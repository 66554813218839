import { Grid } from "@mui/material"
import NonAuthNavBar from "../components/NavBar/NonAuthNavBar"
import { useNavigate } from "react-router-dom";
import { mainColors } from "../components/Theme/Colors/palette";
import Footer from "../components/CombinedComponents/Footer";
import { landingPage } from "../pages/URLs";

const NonAuthLayout = ({children}) => {

    const navigate = useNavigate();

    const links = [
        {
            name: "Home",
            handleClick: () => navigate(landingPage)
        }
    ]

    return (
        <Grid container minHeight="100vh"
        maxWidth={"100%"}
        width="100vw"
        direction="column">
            <Grid item height="auto" width={"100%"}>
                <NonAuthNavBar links={links}/>
            </Grid>

            <Grid item
            width="100%"
            maxWidth={"100%"}
            height={"auto"}
            xs={true}>
                {children}
            </Grid>

            <Grid item height="auto"
            sx={{bgcolor: mainColors.primary,
            px:"8vw", py:"1.5vh"
            }}
            >
                <Footer/>
            </Grid>
        </Grid>
    )
}

export default NonAuthLayout