import { useContext } from "react"
import LayoutContext from "../../../Layout/LayoutProvider"
import NavBarContext from "../Context/NavBarProvider"
import { Grid, IconButton, Tooltip } from "@mui/material"
import { motion } from "framer-motion"

const CollapseView = () => {
    const { navBarWidth } = useContext(LayoutContext)
    const { heightOfAvatar, paddingTop, open, navBarPages, navBarDuration, navButtonDisabled } = useContext(NavBarContext)

    return (
        <Grid item style={{
            height: navBarWidth,
            position: "fixed",
            bottom: 0,
            width: `calc(100vw - ${40}px - ${2*paddingTop}px)`
        }}>
            <motion.div
            style={{ height: "100%", width: "100%" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: open ? 0 : 1 }}
            transition={{ duration: navBarDuration/5000 }}>
                <Grid container
                width="100%"
                height="100%"
                display="flex"
                justifyContent="space-evenly"
                alignItems="center">
                    { navBarPages.map((props, index) => {
                        if (index > 4) return
                        return (
                            <Grid item key={index}
                            height="100%"
                            display="flex"
                            alignItems="center">
                                <Tooltip title={props.label} placement={"top"}>
                                    <IconButton onClick={props.func}
                                    disabled={navButtonDisabled || props?.disabled}>
                                        {props.icon}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )})
                    }
                </Grid>
            </motion.div>
        </Grid>
    )
}

export default CollapseView