import { motion } from "framer-motion"

const FadeInNormal = ({ children, delay = 0, duration = 0.5 }) => {

    return (
        <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: duration, delay: delay }}
        viewport={{ once: true }}
        layout
        style={{height: "100%", width:"100%"}}
        >
            {children}
        </motion.div>
    )
}

export default FadeInNormal