import { Settings } from '@mui/icons-material'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { Grid, Icon, IconButton, SvgIcon } from '@mui/material'
import React from 'react'

const PreferencesIconCustom = ({size, height, disabled}) => {
  return (
    <>
    <Grid container
    style={{
        width: size,
        height: size,
    }}
    display="flex"
    justifyContent="center"
    alignItems="center"
    >
        <Grid item
        style={{
            position: "absolute",
            width: size,
            height: size,
        }}
        >
            <FavoriteBorderOutlinedIcon style={{
                fontSize: `calc(${size})`,
                fill: disabled ? "#5e5e5e" : "black"
            }}/>
        </Grid>
        <Grid item
        style={{
            position: "relative",
            zIndex: 2,
            width: size,
            height: size,
            left: `calc(${size}*0.25)`,
            top:  `calc(${size}*0.08)`
        }}
        >
            <Settings style={{
                fontSize: `calc(${size}*0.6)`,
                fill: disabled ? "#5e5e5e" : "black"
            }}/>
        </Grid>
    </Grid>
    </>
  )
}

export default PreferencesIconCustom