import { Grid } from "@mui/material"
import { useContext } from "react"
import LayoutContext from "./LayoutProvider"
import { motion } from "framer-motion"
import ResponsiveContext from "../ResponsiveDesign/ResponsiveProvider"

const MainBody = ({ children }) => {

    const { mainBodyWidth, navPicWidth, pageFadeDuration, navBarWidth } = useContext(LayoutContext)
    const { isRemoveRightContent, isTablet } = useContext(ResponsiveContext)

    return (
        <Grid container
        style={{
            width: !isRemoveRightContent ? "100%" : mainBodyWidth,
            left: navPicWidth,
            minHeight: "100vh",
            paddingBottom: isTablet ? "" : `calc(${navBarWidth} + 20px)`
        }}
        >
            <motion.div
            style={{
                height: "100%",
                width: "100%",
            }}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            transition={{
                duration: pageFadeDuration/1000
            }}
            >
                {children}
            </motion.div>
        </Grid>
    )
}

export default MainBody