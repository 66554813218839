import { useContext } from "react"
import LayoutContext from "../../../Layout/LayoutProvider"
import NavBarContext from "../Context/NavBarProvider"
import { Grid, IconButton, Tooltip } from "@mui/material"
import { motion } from "framer-motion"

const CollapseView = () => {
    const { navBarWidth } = useContext(LayoutContext)
    const { heightOfAvatar, paddingTop, open, navBarPages, navBarDuration, navButtonDisabled } = useContext(NavBarContext)

    return (
        <Grid item style={{
            width: navBarWidth,
            position: "fixed",
            height: `calc(100vh - ${2*heightOfAvatar}px - ${2*paddingTop*2}px)`,
            top: `calc(${heightOfAvatar}px + ${paddingTop*2}px)`,
        }}>
            <motion.div
            style={{ height: "100%", width: navBarWidth }}
            initial={{ opacity: 0 }}
            animate={{ opacity: open ? 0 : 1 }}
            transition={{ duration: navBarDuration/5000 }}>
                <Grid container
                height="100%"
                display="flex"
                justifyContent="center"
                direction="column"
                width={navBarWidth}>
                    {navBarPages.map((props, index) => {
                        if (index > 4) return
                        return (
                            <Grid item key={index}
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            sx={{my: "1.2vh"}}>
                                <Tooltip title={props.label} placement={"right"}>
                                    <IconButton onClick={props.func}
                                    disabled={navButtonDisabled || props?.disabled}>
                                        {props.icon}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )})
                    }
                </Grid>
            </motion.div>
        </Grid>
    )
}

export default CollapseView