import { Button, Typography } from "@mui/material"
import { mainColors } from "../Theme/Colors/palette"

const CheckedPills = (props) => {

    const pillText = props.text
    const removePill = props.func
    const index = props.index

    return (
        <Button
        variant="contained"
        disableElevation
        disableRipple
        sx={{"&:hover": { backgroundColor: mainColors.background },
        background: mainColors.background,
        mx:"1vw", my:"1vh", minWidth:"13vw"
        }}
        onClick={() => {
            removePill(index)
        }}
        >
            <Typography variant="h6"
            fontWeight="bold"
            fontFamily="Quicksand"
            >
                {pillText.toUpperCase()}
            </Typography>
        </Button>
    )
}

export default CheckedPills