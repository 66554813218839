import { Button, Grid, Typography } from "@mui/material"
import { useContext } from "react"
import NavBarContext from "../Context/NavBarProvider"
import LayoutContext from "../../../Layout/LayoutProvider"
import { motion } from "framer-motion"

const FullView = () => {
    const { paddingLeft, paddingTop, navBarPages, open, navButtonDisabled, navBarDuration, heightOfAvatar } = useContext(NavBarContext)
    const { navPicWidth } = useContext(LayoutContext)

    return (
        <Grid item xs={true}
        style={{position: "fixed", top: `calc(${heightOfAvatar}px + ${paddingTop*3}px)` }}>
            <Grid container
            height="100%"
            width={navPicWidth}
            display="flex"
            alignItems="start"
            justifyContent="center"
            direction="column">
                { navBarPages.map((props, index) => {
                    return (
                        <motion.div
                        style={{ height: "100%", width: navPicWidth }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: open ? 1 : 0 }}
                        transition={{
                            duration: navBarDuration/5000,
                            delay: open ? 0.2 + index*0.04 : 0
                        }}>
                            <Grid item
                            width="100%"
                            display="flex"
                            justifyContent="start"
                            sx={{ paddingLeft: `${paddingLeft}px`}}>
                                <Button disableRipple variant="backCP"
                                startIcon={props.icon}
                                style={{ background: "transparent" }}
                                sx={{ "&.Mui-disabled": { color: "black" } }}
                                onClick={props.func}
                                disabled={navButtonDisabled || props?.disabled}>
                                    <Typography
                                    variant="h6"
                                    color={props?.disabled ? "#5e5e5e" : "black"}
                                    sx={{ ml: "0.6vw" }}>
                                        {props.label}
                                    </Typography>
                                </Button>
                            </Grid>
                        </motion.div>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default FullView