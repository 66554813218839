import { Grid, Typography } from "@mui/material"
import { mainColors } from "../Theme/Colors/palette"
import axios from "../../api/axios"
import { useNavigate } from "react-router-dom"
import { updateProfilePage, matchingPage, settingsPage, subscriptionsPage, homePage } from "../../pages/URLs";
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo"
import useActionCenter from "../../hooks/useActionCenter"
import { UPDATE_NOTIFICATIONS_URL } from "../../pages/URLs"
import ActionCenterCard from "../IndividualComponents/ActionCenterCard"
import NotificationsIcon from '@mui/icons-material/Notifications';
import VerifiedIcon from '@mui/icons-material/Verified'
import FavoriteIcon from '@mui/icons-material/Favorite';
import FaceIcon from '@mui/icons-material/Face';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import SavingsIcon from '@mui/icons-material/Savings';
import SearchIcon from '@mui/icons-material/Search';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

const iconBaseStyle = {
    fontSize: "2vh",
    marginLeft: "1vw",
    marginRight: "1vw"
}

const MobileActionCenter = () => {

    const info = useAccessTokenInfo()
    const user = info?.user || info.email
    const [notifs, setNotifs] = useActionCenter(user)
    const navigate = useNavigate()

    const actionCenterIcons = {
        "matched": <FavoriteIcon style={{
            ...iconBaseStyle,
            fill: "red"
        }}/>,
        "newCandidate": <FavoriteIcon style={{
            ...iconBaseStyle,
            fill: "purple"
        }}/>,
        "updateProfile": <FaceIcon style={{
            ...iconBaseStyle,
            fill: "purple"
        }}/>,
        "refVerified": <VerifiedIcon style={{
            ...iconBaseStyle,
            fill: "black"
        }}/>,
        "addInterests": <LocalFloristIcon style={{
            ...iconBaseStyle,
            fill: "green"
        }}/>,
        "activateSubscription": <SavingsIcon style={{
            ...iconBaseStyle,
            fill: "black"
        }}/>,
        "useSearches": <SearchIcon style={{
            ...iconBaseStyle,
            fill: "black"
        }}/>,
        "upgradeSubscription": <ArrowCircleUpIcon style={{
            ...iconBaseStyle,
            fill: "red"
        }}/>,
    }

    const actionCenterLinks = {
        "matched": () => navigate(homePage),
        "newCandidate": () => navigate(matchingPage),
        "refVerified": () => navigate(homePage),
        "updateProfile": () => navigate(updateProfilePage),
        "updateStatus": () => navigate(settingsPage),
    }

    const dismiss = async (notifVar) => {
        await axios.post(UPDATE_NOTIFICATIONS_URL, 
            JSON.stringify({ user: user, notifs: [{ notifId: notifVar, notifState: -1 }] }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_UPDATE_NOTIFICATIONS_KEY}` },
                withCredentials: true
            }
        ).then((res) => {
            const newNotifs = notifs.filter(n => n.notifId !== notifVar);
            setNotifs(newNotifs)
        })
    }

    const zeroNotifs = !notifs || notifs?.length === 0;

    return (
        <div
        style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            paddingLeft: "2vw",
            paddingRight: "2vw",
            paddingTop: "2vh"
        }}
        >
        <Grid container
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: `3px solid ${mainColors.primary}`,
            background: "white",
            borderRadius: 20
        }}
        sx={{px: "2vw", py: "2vh"}}
        >
            <Grid items style={{display: "flex", alignItems: "center"}} sx={{mx: 0.5}}>
                <NotificationsIcon style={{fontSize: "4vh", fill: mainColors.blue}}/>
            </Grid>
            <Grid item xs={true}>
                {
                zeroNotifs ?
                <Typography>No notifications</Typography> :
                notifs?.map((notif, index) => {
                    return (
                        <ActionCenterCard 
                        key={notif.notifId}
                        text={notif.title}
                        variable={notif.notifId}
                        dismiss={dismiss}
                        func={actionCenterLinks[notif.notifName]}
                        icon={actionCenterIcons[notif.notifName]
                            || <NotificationsIcon style={{fontSize: "2vh"}}
                        sx={{mx: "1vw"}}
                        />}
                        />
                    )
                })}
            </Grid>
        </Grid>
        </div>
    )
}

export default MobileActionCenter