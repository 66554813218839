import { Button, Typography } from "@mui/material"

const DispPills = (props) => {

    const array = props.array
    const color = props.color

    return (
        <>
            {array.map((text) => {
                return (
                <Button
                key={text}
                variant="contained"
                style={{border:"2px solid", 
                borderColor:color, 
                background: color, 
                minWidth:"8vw"}}
                disableElevation
                disabled
                sx={{mx:"0.3vw", my:"0.3vh", px:"2vw"}} 
                >
                    <Typography variant="h6" style={{color:"white", fontWeight:"bold", fontFamily:"Quicksand"}}>

                        {text.toUpperCase()}

                    </Typography>
                </Button>
                )
            })}
            
            
        </>
    )
}

export default DispPills