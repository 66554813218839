import { Grid } from '@mui/material'
import React from 'react'
import CircularProgressWithLabel from './CircularProgressWithLabel'

const CPProgress = ({ progress }) => {
  return (
    <Grid container position="fixed" 
    width="22vw" 
    direction="column">
        <Grid container direction="column"
        display="flex"
        alignItems="center"
        >
            <Grid item xs={12}
            >
                <CircularProgressWithLabel value={progress} size="20vh" thickness={9}/>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default CPProgress