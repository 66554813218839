import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import jwt_decode from "jwt-decode"
import { loginPage, unauthorizedPage } from "../pages/URLs";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();


    const decoded = auth?.accessToken
        ? jwt_decode(auth.accessToken)
        : undefined

    const roles = decoded?.UserInfo?.roles || []

    return (
        roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.accessToken //changed from user to accessToken to persist login after refresh
                ? <Navigate to={unauthorizedPage} state={{ from: location }} replace />
                : <Navigate to={loginPage} state={{ from: location }} replace />
    );
}

export default RequireAuth;