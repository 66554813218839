import { useEffect, useState } from "react"
import axios from "../api/axios"
import useStripeKey from "../hooks/useStripeKey"

const Payment = () => {

    const response = useStripeKey()
    if (response) {
        const url = response.data.url
        window.location.href = url
    }

}

export default Payment