import { useState, useCallback, useEffect } from "react"
import DropBox from "../IndividualComponents/DropBox"
import ShowImage from "../IndividualComponents/ShowImage"
import imageCompression from "browser-image-compression"
import axios from "../../api/axios"
import { GET_PICS_URL, UPLOAD_PICS_URL } from "../../pages/URLs"
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo"
import ShowImageMatch from "../IndividualComponents/ShowImageMatch"

const DragAndDropCompMatch = ({ images, setImages, user }) => {

    const accessTokenEmail = user

    useEffect(() => {
        setImages([])
        for (let i = 0; i < 5; i++) {
            axios.post(GET_PICS_URL, 
                JSON.stringify({
                    email: accessTokenEmail,
                    picKey: `${i}`
                }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_GET_PICS_KEY}` },
                    withCredentials: true
                }
                ).then(res => {
                    if (res.data === "None") {
                        return
                    }
                    setImages((prevState) => [
                        ...prevState,
                        { id: i, src: res.data.src },
                    ])
                })
        }
    }, [])

    const [indexId, setIndexId] = useState([0,1,2,3,4]);
    // TODO cleanupIds need to be passed into this from page buttons and then call deletepics api function on next button
    const [cleanUpIds, setCleanUpIds] = useState([]);
    const [errMsg, setErrMsg] = useState("");

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length + images.length > 5) {
            setErrMsg("You can only upload a maximum of 5 pictures")
            return
        }
        acceptedFiles.forEach((file) => {
            if (file.size > 50000000) {
                return
            }
            const imageFile = file
            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 700,
                useWebWorker: true
            }
            imageCompression(imageFile, options).then(compressedFile => {
                var reader = new FileReader()
                reader.readAsDataURL(compressedFile)
                reader.onloadend = () => {
                    const copyOfIndex = JSON.parse(JSON.stringify(indexId))
                    const idVal = Math.min(...copyOfIndex)
                    const remCleanUpIndex = cleanUpIds.indexOf(idVal);
                    if (remCleanUpIndex > -1) {
                        cleanUpIds.splice(remCleanUpIndex, 1);
                    }
                    setCleanUpIds([...cleanUpIds])

                    // ? If we need to cut down calls to the api we can upload all images in one go

                    try {
                        axios.post(UPLOAD_PICS_URL, 
                            JSON.stringify({
                                email: accessTokenEmail,
                                pic: { src: reader.result, id: idVal }
                            }),
                            {
                                headers: { 'Content-Type': 'application/json',
                                'x-functions-key': `${process.env.REACT_APP_UPLOAD_PICS_KEY}` },
                                withCredentials: true
                            }
                            )
                    } catch (err) {
                        console.log(err)
                        // TODO continue
                    }
                    setImages((prevState) => [
                        ...prevState,
                        { id: idVal, src: reader.result },
                    ])
                    const removeIndex = indexId.indexOf(idVal);
                    if (removeIndex > -1) {
                        indexId.splice(removeIndex, 1);
                    }
                    setIndexId([...indexId])
                }
            })
        })
    })

    return (
        <div
        style={{padding: "1.5vh"}}
        >
            {/* <DropBox onDrop={onDrop} errMsg={errMsg}/> */}
            <ShowImageMatch images={images} setImages={setImages} setErrMsg={setErrMsg}
            setCleanUpIds={setCleanUpIds}
            cleanUpIds={cleanUpIds}
            indexId={indexId}
            setIndexId={setIndexId}
            />
        </div>
    )
}

export default DragAndDropCompMatch