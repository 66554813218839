import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullMatch from "../components/CombinedComponents/FullMatch";
import MatchingTiles from "../components/CombinedComponents/MatchingTiles";
import LoadingComp from "../components/IndividualComponents/LoadingComp";
import useAccessTokenInfo from "../hooks/useAccessTokenInfo";
import useFullMatching from "../hooks/useFullMatching";
import MainBody from "../Layout/MainBody";
import RightContent from "../Layout/RightContent";
import { homePage } from "./URLs";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const cardContent = [
    "Matching stats coming here soon…"
]

const MatchProfile = () => {

    const navigate = useNavigate()

    const user = useAccessTokenInfo("user")

    const info = useAccessTokenInfo()
    const isReadyForMatches = info?.readyForMatches || false
    const isSubbed = info?.isSubbed || false
    const isEmailVerified = info?.isEmailVerified || false
    useEffect(() => {
        if (!isReadyForMatches || !isSubbed || !isEmailVerified) {
            navigate(homePage)
        }
    }, [])

    const quickRes = useFullMatching(user)
    
    const [res, setRes] = useState(null);
    const [accepted, setAccepted] = useState(null);
    const [rejected, setRejected] = useState(null);

    const acceptedFunc = () => {
        setAccepted(true)
    }
    const rejectedFunc = () => {
        setRejected(true)
    }

    setTimeout(() => {
        setRes(quickRes)
    }, 1000) 

    if (!res) {
        return (
            <MainBody>
                <Grid container
                style={{
                    height: "100vh",
                    width: "100%",
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                    <LoadingComp width="15vw" />
                </Grid>
            </MainBody>
        )
    }


    return (
        <>
            <SEO
                title={seoText.default.title}
                description={seoText.default.description}
            />
            <MainBody>
                <Grid item
                style={{height: "auto"}}
                sx={{px:"1.5vw"}}
                display="flex"
                alignItems="start"
                >
                    <FullMatch matchingData={res.data}
                    accepted={acceptedFunc}
                    rejected={rejectedFunc}
                    />   
                </Grid>
            </MainBody>


            <RightContent>
                <Grid container
                style={{
                    width: "100%",
                    height: "100%",
                    wordWrap: "break-word",
                    overflow: "auto"
                }}
                sx={{
                    px: "1vw",
                    my: "1vh"
                }}
                >
                    <Grid container>
                    {
                        cardContent.map((text, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Typography variant="h6">
                                        {text}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                </Grid>
            </RightContent>
        </>
    )
        
}
    
export default MatchProfile