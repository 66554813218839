import { TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import StructureContext from "../../../StructureDecoder/context/StructureProvider";

const EMAIL_REGEX = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const CPTextComp = (props) => {

    const { page, setPage, required, setRequired } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])
    
    const isEmail = thisPage[path[1]][path[2]]?.email
    const [isValidEmail, setIsValidEmail] = useState(true)

    const handleFocus = () => {
        if (required.includes(path[2])) {
            const index = required.indexOf(path[2]);
            required.splice(index, 1);
            setRequired(required)
        }
    }

    return (
        <TextField sx={{mt:2, px:1}}
        value={thisPage[path[1]][path[2]].value}
        onChange={(e) => {
            thisPage[path[1]][path[2]].value = e.target.value
            if (isEmail) {
                setIsValidEmail(EMAIL_REGEX.test(e.target.value))
            }
            setPage(path[0], thisPage)
        }}
        size="small"
        fullWidth
        margin="dense"
        error={required.includes(path[2]) || (!isValidEmail && isEmail)}
        onFocus={handleFocus}
        onBlur={() => {
            if (thisPage[path[1]][path[2]].value === "") {
                setIsValidEmail(true)
            }
        }}
        helperText={isValidEmail ? "" : "Please enter a valid email"}
        />
    )
}

export default CPTextComp