import { Autocomplete, TextField } from "@mui/material"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import StructureContext from "../../../StructureDecoder/context/StructureProvider";

const CPAutocompleteCountry = (props) => {
    const [countries, setCountries] = useState([]);
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);

    const { page, setPage, required, setRequired } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])

    useEffect(() => {
        async function fetchData() { await axios.get("https://countriesnow.space/api/v0.1/countries/positions")
        .then(res => {
            const data = res.data.data
            const countriesList = data.map(countryInfo => {
                return countryInfo.name
            })
            setCountries(countriesList)
        })}
        fetchData()
    },[])

    const handleFocus = () => {
        setFocused(true)
        setOpen(true)
        if (required.includes(path[2])) {
            const index = required.indexOf(path[2]);
            required.splice(index, 1);
            setRequired(required)
        }
    }

    return (
        <Autocomplete
        fullWidth
        options={countries}
        value={thisPage[path[1]][path[2]].value}
        onChange={(e, newval) => {
            thisPage[path[1]][path[2]].value = newval
            setPage(path[0], thisPage)
        }}
        onFocus={handleFocus}
        open={open}
        onOpen={() => {
            if (focused) {
                setOpen(true)
            }
        }}
        onClose={() => {
            setOpen(false)
        }}
        onBlur={() => {
            setFocused(false)
        }}
        renderInput={(params) => <TextField
            {...params}
            sx={{mt:2, px:1}}
            margin="dense"
            size="small"
            value={thisPage[path[1]][path[2]].value}
            />
        }
        />
    )
}

export default CPAutocompleteCountry