export const page_1 = {
    "Personal": {
        "completionTime": 8,
        "firstname": {
            value: "",
            label: "Firstname*",
            component: "text",
            fill: "half",
        },
        "surname": {
            value: "",
            label: "Surname",
            component: "text",
            fill: "half"
        },
        "nickname": {
            value: "",
            label: "Nickname",
            component: "text",
            fill: "half"
        },
        "user": {
            value: "",
            label: "Username",
            component: "text",
            fill: "half",
            required: ""
        },
        "gender": {
            value: "",
            label: "Gender*",
            component: "dropdown",
            fill: "half",
            discreteVals: ["Male", "Female", "Other", "Prefer not to say"]
        },
        "height": {
            value: "1.86",
            label: "Height (m)*",
            component: "slider",
            fill: "half",
            discreteVals: [1.2,2.2],
        },
        "DOB": {
            value: "",
            label: "Date of Birth*",
            component: "date",
            fill: "half",
        },
        "empty": {
            component: "empty",
            fill: "half"
        },
        "country": {
            value: "",
            label: "Country of residence*",
            component: "autocompleteCountry",
            fill: "half"
        },
        "city": {
            value: "",
            label: "City of residence",
            component: "autocompleteCity",
            fill: "half",
            dependsOn: {
                field: "country",
                value: ["*"]
            }
        },
        "homecountry": {
            value: "",
            label: "Home Country*",
            component: "autocompleteCountry",
            fill: "half",
            info: "The country where you come from",
        },
        "homecity": {
            value: "",
            label: "Home City",
            component: "autocompleteCity",
            fill: "half",
            dependsOn: {
                field: "homecountry",
                value: ["*"]
            }
        },
        "ethinicity": {
            value: "",
            label: "Ethnicity*",
            component: "autocompleteEthnicity",
            fill: "half",
        },
        "marital": {
            value: "",
            label: "Marital Status*",
            component: "dropdown",
            fill: "half",
            discreteVals: ["Single", "Divorced", "Other"]
        },
        "pref_language": {
            value: "",
            label: "Preferred Language",
            component: "autocompleteLanguage",
            fill: "half"
        },
        "other_language": {
            value: "",
            label: "Other Language",
            component: "autocompleteLanguage",
            fill: "half"
        }
    },
    "Employment": {
        "completionTime": 2,
        "employment_status": {
            value: "",
            label: "Employment Status",
            component: "dropdown",
            fill: "half",
            discreteVals: ["Employed", "Student", "Unemployed", "Other"],
        },
        "empty": {
            component: "empty",
            fill: "half"
        },
        "level": {
            value: "",
            label: "Level",
            component: "dropdown",
            fill: "half",
            discreteVals: ["High School", "Foundation Degree", "Degree", "Post Graduate Study", "Other"],
            dependsOn: {
                field: "employment_status",
                value: ["Student"]
            }
        },
        "job_title": {
            value: "",
            label: "Job Title",
            component: "text",
            fill: "half",
            dependsOn: {
                field: "employment_status",
                value: ["Employed"]
            }
        },
        "subject": {
            value: "",
            label: "Subject",
            component: "autocompleteSubject",
            fill: "half",
            dependsOn: {
                field: "employment_status",
                value: ["Student"]
            }
        },
        "industry": {
            value: "",
            label: "Industry",
            component: "autocompleteIndustry",
            fill: "half",
            dependsOn: {
                field: "employment_status",
                value: ["Employed"]
            }
        }
    },
}

export const page_2 = {
    "About You": {
        "completionTime": 15,
        "description": {
            value: "",
            label: "Tell us about you*",
            component: "multiline",
            fill: "full",
        },
        "goals": {
            value: "",
            label: "Goals and aspirations",
            component: "multiline",
            fill: "full"
        },
        "oneLiner": {
            value: "",
            label: "Tell us your mission statement",
            component: "text",
            fill: "full"
        },
    }
}

export const page_3 = {
    "Spiritual Life": {
        "completionTime": 5,
        "japa": {
            value: "",
            label: "Number of daily rounds*",
            component: "dropdown",
            fill: "half",
            discreteVals: ["0-3", "4-7", "8-11", "12-15", "16-20", "20+"],
        },
        "offering": {
            value: "",
            label: "Do you offer food at home?*",
            component: "dropdown",
            fill: "half",
            discreteVals: ["Never", "Sometimes", "Often", "Always"],
        },
        "principles": {
            value: "",
            label: "How many regulative principles do you follow?*",
            component: "dropdown",
            fill: "full",
            discreteVals: [0, 1, 2, 3, 4],
        },
        "initiation_status": {
            value: "",
            label: "Initiation Status*",
            component: "dropdown",
            fill: "half",
            discreteVals: ["Not initiated", "Inspired by a guru", "Aspiring for a guru", "First initiated", "Second initiated"],
        },
        "spiritual_name": {
            value: "",
            label: "Spiritual Name",
            component: "text",
            fill: "half",
            dependsOn: {
                field: "initiation_status",
                value: ["First initiated", "Second initiated"]
            }
        },
        "first_init": {
            value: "",
            label: "Year of first initiation",
            component: "year",
            fill: "half",
            dependsOn: {
                field: "initiation_status",
                value: ["First initiated"]
            }
        },
        "second_init": {
            value: "",
            label: "Year of second initiation",
            component: "year",
            fill: "half",
            dependsOn: {
                field: "initiation_status",
                value: ["Second initiated"]
            }
        },
        "services": {
            value:[],
            label: "Services",
            component: "pill",
            fill: "full",
            info: "Select the services you are involved in",
            discreteVals: ["Cooking", "Cleaning", "Preaching", "Deity worship", "Leadership", "Community services", "Oragnisation", "Management", "Harinam", "Book distribution", "Kirtan", "Festivals", "Teaching", "Drama", "Art", "Photography", "Videography", "Technical", "Other"]
        },
        "studies": {
            value:[],
            label: "Spiritual Studies",
            component: "pill",
            fill: "full",
            info: "Select the courses you have completed",
            discreteVals: ["Bhakti Sastri", "Bhakti Vaibhava I", "Bhakti Vaibhava II", "Bhakti Sarvabhauma (CC)", "Gita Life", "ISKCON Disciples Course", "Other"]
        },
        "sp_books": {
            value:[],
            label: "Which of Srila Prabhupadas books have you read?",
            component: "pill",
            fill: "full",
            discreteVals: [ "Bhagavad-gita As It Is", "Srimad-Bhagavatam", "Sri Caitanya-caritamrta", "Teachings of Lord Caitanya", "The Nectar of Devotion", "The Nectar of Instruction", "Easy Journey to Other Planets", "KRSNA, The Supreme Personality of Godhead", "Perfect Questions, Perfect Answers", "Teachings of Lord Kapila, the Son of Devahuti", "Teachings of Queen Kunti", "The Science of Self Realization", "Raja Vidya: The King of Knowledge", "Light of the Bhagavata", "Sri Isopanisad", "The Journey of Self Discovery", "A Second Chance: The Story of a Near Death Experience", "Other" ]
        },
        "more_info": {
            value: "",
            label: "Tell us a bit more about your spiritual life...",
            component: "multiline",
            fill: "full"
        }
    }
}

export const page_4 = {
    "Partner Preferences": {
        "completionTime": 2,
        "relocation": {
            value: "",
            label: "Would you be willing to relocate?*",
            component: "radio",
            fill: "full",
            discreteVals: ["Yes", "No", "Not sure", "It depends"],
        },
        "height": {
            value: [1.5,1.8],
            label: "Height (m)*",
            component: "rangeSlider",
            fill: "half",
            discreteVals: [1.2,2.2],
            defaultVals: [1.5,1.8],
            increment: 0.01,
        },
        "age": {
            value: [25, 40],
            label: "Age*",
            component: "rangeSlider",
            fill: "half",
            discreteVals: [18, 70],
            defaultVals: [25, 40],
            increment: 1,
        },
        "children": {
            value: "",
            label: "How many children would you like to have?*",
            component: "dropdown",
            fill: "half",
            discreteVals: ["0", "1", "2", "3", "4", "5", "6+"],
        },
        "living_situation": {
            value: "",
            label: "Where would you like to live after you are married?*",
            component: "dropdown",
            fill: "half",
            discreteVals: ["Live with my parents", "Live with spouse parents", "Live separate to parents", "Flexible", "Unsure", "Other"],
        }
    }
}

export const page_5 = {
    "References": {
        "completionTime": 2,
        "ref1_name": {
            value: "",
            component: "text",
            label: "Reference Name",
            fill: "half"
        },
        "ref1_relationship": {
            value: "",
            component: "text",
            label: "Reference Relationship",
            info: "Describe the relationship you have with this reference e.g. father, mother, spiritual mentor",
            fill: "half"
        },
        "ref1_email": {
            value: "",
            component: "text",
            label: "Reference Email",
            fill: "full",
            email: true
        },
        "ref2_name": {
            value: "",
            component: "text",
            label: "Reference Name",
            fill: "half"
        },
        "ref2_relationship": {
            value: "",
            component: "text",
            label: "Reference Relationship",
            info: "Describe the relationship you have with this reference e.g. father, mother, spiritual mentor",
            fill: "half"
        },
        "ref2_email": {
            value: "",
            component: "text",
            label: "Reference Email",
            fill: "full",
            email: true
        }
    }
}

export const numOfPages = 5