//router urls
export const adminPage = '/admin'
export const completeProfilePage = '/completeprofile'
export const homePage = '/home'
export const landingPage = '/'
export const loginPage = '/login'
export const matchmakerPage = '/matchmaker'
export const registerPage = '/register'
export const unauthorizedPage = '/unauthorized'
export const matchingPage = '/matching'
export const matchProfilePage = '/matchprofile'
export const paymentPage = '/payment'
export const paymentFailedPage = '/paymentfailed'
export const paymentSuccessPage = '/paymentsuccess'
export const updateProfilePage = '/updateprofile'
export const settingsPage = '/settings'
export const subscriptionsPage = '/subscriptions'
export const heroPage = '/hero'
export const faqPage = '/faqs'
export const dataProtectionPolicy = '/dataprotection'
export const cookiePolicy = '/cookiepolicy'
export const privacyPolicy = '/privacypolicy'
export const termsAndConditionsPage = '/termsandconditions'
export const guidelinesPage = '/communityguidelines'
export const contactUsPage = '/contactus'
export const usefulLinksPage = '/usefullinks'
export const verifyUser = '/verifyuser/:refResponse/:verificationToken'
export const verifyEmailPage = '/verifyemail/:verificationToken/:email'
export const galleryPage = '/gallery'
export const forgotPasswordPage = '/forgotpassword'
export const resetPasswordPage = '/resetpassword/:forgotPasswordToken'
export const DeleteProfileSuccessfulPage = '/deleteprofilesuccessful'
export const revisitProfilePage = '/revisitProfile/:matchUserId/:likeOrDislike'

//api urls
export const LOGIN_URL = `/api/Login`
export const LOGGER_URL = '/logger'
export const REGISTER_URL= `/api/CreateNewUser`
export const LOGOUT_URL = '/api/Logout'
export const REFRESH_URL = `/api/NewAccessToken`
export const EXISTING_EMAIL_URL = `/api/CheckExistingEmail`
export const PERSONAL_INFO_URL = `/api/CreatePersonalInfo`
export const ABOUT_ME_INFO_URL = `/api/CreateAboutMeInfo`
export const SPIRITUAL_INFO_URL = `/api/CreateSpiritualInfo`
export const PARTNER_PREFS_INFO_URL = `/api/CreatePartnerPrefInfo`
export const MATCHING_URL = `/api/Matching`
export const PREF_PILLS_DONE = `/api/CheckPrefPillsDone`
export const PREF_PILLS_URL = `/api/CreatePrefPill`
export const READY_FOR_MATCH = `/api/CheckReadyForMatches`
export const FULL_MATCHING_URL = `/api/MatchingFullInfo`
export const MATCH_RESULT_URL = `/api/MatchingResult`
export const TEST_STRIPE_CHECKOUT = `/api/CreateCheckoutSession`
export const UPLOAD_PICS_URL = `/api/UploadPics`
export const GET_PERSONAL_URL = `/api/GetPersonalInfo`
export const GET_ABOUT_ME_URL = `/api/GetAboutMeInfo`
export const GET_SPIRITUAL_URL = `/api/GetSpiritualInfo`
export const GET_REFERENCES_URL = `/api/GetReferencesInfo`
export const GET_PARTNER_PREF_URL = `/api/GetPartnerPrefInfo`
export const GET_NOTIFICATIONS_URL = `/api/GetNotifications`
export const UPDATE_NOTIFICATIONS_URL = `/api/UpdateNotifications`
export const GET_ALL_INFO_URL = `/api/GetAllInfo`
export const GET_PICS_URL = `/api/GetPics`
export const DELETE_PICS_URL = `/api/DeletePics`
export const CREATE_REFERENCES_URL=`/api/CreateReferencesInfo`
export const CREATE_SETTINGS_URL=`/api/CreateSettingsInfo`
export const CREATE_INTERESTED_USER_URL=`/api/CreateInterestedUser`
export const CREATE_PORTAL_URL=`/api/CreatePortalSession`
export const GET_USER_MATCH_HISTORY=`/api/GetUserMatchHistory`
export const VERIFY_USER_EMAIL_URL=`/api/VerifyUserEmail`
export const VERIFY_USER_URL=`/api/VerifyUser`
export const DELETE_ALL_URL=`/api/DeleteAllInfo`
export const FORGET_PASSWORD_URL=`/api/ForgotPassword`
export const RESET_PASSWORD_URL=`/api/ResetPassword`
export const GET_SETTINGS_URL=`/api/GetSettingsInfo`
export const UPDATE_MATCH_RESULT=`/api/MatchingResultUpdate`
export const MATCH_REVISIT_INFO=`/api/MatchingRevisitInfo`
// ! roll all function keys