import axios from '../api/axios';
import useAuth from './useAuth';
import jwt_decode from 'jwt-decode'
import { REFRESH_URL } from '../pages/URLs';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get(REFRESH_URL, {
            headers: { 'x-functions-key': `${process.env.REACT_APP_NEW_ACCESS_TOKEN_KEY}` },
            withCredentials: true
        });
        setAuth(prev => {
            return {
                ...prev,
                roles: jwt_decode(response.data)?.userInfo?.roles || [],
                accessToken: response.data
            }
        });
        return response.data;
    }
    return refresh;
};

export default useRefreshToken;
