// UnverifiedIconWithToolTip.js
import UnverifiedIcon from "../IndividualComponents/UnverifiedIcon";
import { useState } from "react";
import { Tooltip, IconButton, tooltipClasses } from "@mui/material";
import { Favorite } from "@mui/icons-material";

const UnverifiedIconWithToolTip = (props) => {

    const [toolTipOpen, setToolTipOpen] = useState(false);
    const handleClick = () => {setToolTipOpen(true)}

    return (
        <Tooltip 
        placement="bottom"
        title={props.tooltipText}
        arrow
        open={toolTipOpen}
        onOpen={() => setToolTipOpen(true)}
        onClose={() => setToolTipOpen(false)}
        sx={{py:0}}
        PopperProps={{
            modifiers: [{
                name: "offset",
                options: { offset: [0, -9] },
            }]
        }}
        >
            <IconButton onClick={handleClick}>
                <UnverifiedIcon/>
            </IconButton>
        </Tooltip>
    )
}

export default UnverifiedIconWithToolTip