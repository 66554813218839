import { Grid, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone'
import KMButton from './KMButton';

const DropBox = ({ onDrop, errMsg }) => {

    const {
      getRootProps,
      getInputProps,
      acceptedFiles,
      open,
      isDragAccept,
      isFocused,
      isDragReject,
    } = useDropzone({
      accept: {
        "image/jpeg": [],
        "image/png": []
      },
      onDrop,
      noClick: true,
      noKeyboard: true,
    });

    return (
      <div
      className="dropbox"
      {...getRootProps({})}
      >
        <Grid container>
          <Grid item xs={12}>
            <input {...getInputProps()}/>
            <div style={{border: "2px dashed", borderRadius: 20, paddingTop: "5vh", paddingBottom: "5vh"}}>
            <Typography 
            align="center"
            variant="h5"
            >
              Drag your pics here!
            </Typography>
            <Typography 
            align="center"
            variant="h6"
            >
              Only *.jpeg and *.png accepted
            </Typography>
            </div>
          </Grid>
          <Grid item xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{my:"2vh"}}
          >
            <KMButton text="Select file" onClick={open}/>
            <Typography 
            style={{ fontSize: "1.5vh" }}
            color="red"
            >
              {errMsg}
            </Typography>
          </Grid>

        {/* <button type="button" className="btn" onClick={open}>
          Click to select file
        </button> */}
        </Grid>
      </div>
    );
  }
  export default DropBox