import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { registerPage } from "../../pages/URLs"
import { useContext } from "react"
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider"
import KMButton from "../IndividualComponents/KMButton"
import homeText from "../../text/home.json"

const Testimonials = () => {

    const { isTabletLanding } = useContext(ResponsiveContext)

    const navigate = useNavigate()

    return (
        <Grid container>

            <Grid item xs={12}>
                <Typography variant="h2"
                sx={{mb:"4vh"}}
                >
                    What have others said...
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Grid container
                spacing={"6vw"}
                >

                    <Grid item xs={ isTabletLanding ? 6 : 12 }>
                        <Grid container
                        spacing={"5vh"}
                        >

                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    {homeText.testimonials.one.name}
                                </Typography>
                                <Typography variant="h6">
                                    {homeText.testimonials.one.text}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    {homeText.testimonials.two.name}
                                </Typography>
                                <Typography variant="h6">
                                    {homeText.testimonials.two.text}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={ isTabletLanding ? 6 : 12 }>
                        <Grid container
                        spacing={"5vh"}
                        >

                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    {homeText.testimonials.three.name}
                                </Typography>
                                <Typography variant="h6">
                                    {homeText.testimonials.three.text}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    {homeText.testimonials.four.name}
                                </Typography>
                                <Typography variant="h6">
                                    {homeText.testimonials.four.text}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={12}
            sx={{mt:"4vh"}}
            display="flex"
            justifyContent="flex-end"
            >
                <KMButton endArrow
                text={homeText.testimonials.button}
                variant={"impTitle"}
                onClick={()=>navigate(registerPage)}/>
            </Grid>

        </Grid>
    )
}

export default Testimonials