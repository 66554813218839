import { useContext } from "react"
import { NavBarProvider } from "./Context/NavBarProvider"
import LayoutContext from "../../Layout/LayoutProvider"
import DesktopNavBar from "./Desktop/DesktopNavBar"
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider"
import TabletNavBar from "./Tablet/TabletNavBar"
import MobileNavBar from "./Mobile/MobileNavBar"

const KMNavBar = () => {
    const { isLaptop, isTablet } = useContext(ResponsiveContext)
    const { navPicWidth } = useContext(LayoutContext)
    return (
        <NavBarProvider>
            { isLaptop ? <DesktopNavBar/> :
                isTablet ? <TabletNavBar/> : <MobileNavBar/> }
        </NavBarProvider>
    )
}

export default KMNavBar