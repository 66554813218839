import { Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import { useMemo, useState } from 'react'
import { mainColors } from '../Theme/Colors/palette'
import { motion } from 'framer-motion'
import axios from '../../api/axios'
import { CREATE_INTERESTED_USER_URL } from '../../pages/URLs'
import LoadingComp from '../IndividualComponents/LoadingComp'
import KMButton from '../IndividualComponents/KMButton'

const viewportWidth = 400
const viewportHeight = 400

function boxMullerTransform() {
    const u1 = Math.random();
    const u2 = Math.random();
    
    const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
    
    return z0;
}

function getNormalRandomNumber(mean, stddev) {
    const z0 = boxMullerTransform();
    
    return z0 * stddev + mean;
}

const distanceFromCentre = (x, y) => {
    return Math.sqrt(Math.pow(viewportHeight/2 - y, 2) + Math.pow(viewportWidth/2 - x, 2))/Math.sqrt(Math.pow(viewportHeight/2, 2) + Math.pow(viewportWidth/2, 2))
}

const HeroInterest = () => {

    const EMAIL_REGEX = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isLaptop = useMediaQuery('(min-width:1280px)');
    const isTablet = useMediaQuery('(min-width:601px)');

    const resFontSize = (laptop, tablet, mobile) => {
        return isLaptop ? laptop
               : isTablet ? tablet
               : mobile
    }

    const [interestedEmail, setInterestedEmail] = useState("");
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const numOfSparklez = 250

    const handleChange = (e) => {
        setInterestedEmail(e.target.value)
    }

    const handleSubmit = async () => {

        if (!EMAIL_REGEX.test(interestedEmail)) {
            setInvalidEmail(true)
            return
        }

        setLoading(true)

        try {
            await axios.post(CREATE_INTERESTED_USER_URL, 
                JSON.stringify({ email: interestedEmail }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_INTERESTED_USER_KEY}` },
                    withCredentials: true
                }
            ).then(() => {
                setTimeout(() => {
                    setLoading(false)
                    setSuccess(true)
                }, 1500)
            })
        } catch (err) {
            console.log(err?.response)
            setLoading(false)
        }
    }

    const colors = [ mainColors.background, mainColors.primary, "#fff"]

    const generatorSparklez = (n) => {
        const numArr = [...Array(n).keys()]
        const pathsArr = numArr.map(() => {

            const r = Math.random() * viewportWidth * 3/4 + (1/4 * viewportWidth)
            const theta = Math.random() * 2 * Math.PI
            const xcoord = viewportWidth/2 + r * Math.cos(theta)
            const ycoord = viewportHeight/2 + r * Math.sin(theta)
            const distance = distanceFromCentre(xcoord, ycoord)

            return {
                cx: xcoord,
                cy: ycoord,
                radius: distance*0.9 + 0.1,
                color: Math.floor(Math.random() * 3),
                delay: distance * 7,
                duration: 5,
                opacity: distance + 0.1,
                repeatDelay: 2
            }
        })
        return pathsArr
    }

    const dots = useMemo(() => generatorSparklez(numOfSparklez), [numOfSparklez])

    return (
        <Grid container
        style={{ background: "#000", height: "100vh", width: "100vw", maxWidth: "100%", maxHeight: "100%" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        >
            <motion.svg
            viewBox={`0 0 ${viewportWidth} ${viewportHeight}`}
            width={"100%"}
            height={"100%"}
            style={{ preserveAspectRatio: "xMidYMid slice" }}
            // style={{ width: "100vw", maxWidth: "100%", height: "100vw", maxHeight: "100%" }}
            >

            {
                        dots.map((props) => {
                            return (
                                <motion.circle
                                cx={props.cx}
                                cy={props.cy}
                                r={props.radius}
                                fill={colors[props.color]}
                                fillOpacity={props.opacity}
                                initial={{
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: 1
                                }}
                                transition={{
                                    delay: props.delay,
                                    duration: 2,
                                    repeatType: "reverse",
                                    repeat: Infinity,
                                    repeatDelay: props.repeatDelay
                                }}
                                />
                            )
                        })
                    }
            </motion.svg>
            <Grid container
            style={{
                backgroundColor: "transparent",
                zIndex: 3,
                position: "absolute",
                width: resFontSize("min(400px, 40vw)", "min(370px, 55vw)", "min(285px, 73vw)"),
                minHeight: "min(150px, 20vh)"
            }}   
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{
                    height: "100%",
                    width: "100%"
                }}
                >
                    <Grid item xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <Typography
                        style={{ fontSize: resFontSize("50px", "40px", "35px"), color: mainColors.background, textAlign: "center" }}
                        align="center"
                        >
                            Krishna Marriage
                        </Typography>
                    </Grid>
                </motion.div>

                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
                viewport={{ once: true }}
                style={{
                    height: "100%",
                    width: "100%"
                }}
                >
                    <Grid item xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <Typography
                        style={{ fontSize: resFontSize("22px", "17px", "14px"), color: mainColors.background, textAlign: "center" }}
                        sx={{ mb: resFontSize("20px", "20px", "15px") }}
                        align="center"
                        >
                            From moments of joy, to a lifetime of bliss
                        </Typography>
                    </Grid>
                </motion.div>

                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 2, duration: 1 }}
                viewport={{ once: true }}
                style={{
                    height: "100%",
                    width: "100%"
                }}
                >
                    <Grid item xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <Typography
                        style={{ fontSize: resFontSize("20px", "16px", "13px"), color: mainColors.background, textAlign: "center" }}
                        align="center"
                        >
                            We are improving your experience to help you
                        </Typography>
                    </Grid>
                    <Grid item xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <Typography
                        style={{ fontSize: resFontSize("25px", "22px", "17px"), color: mainColors.background, textAlign: "center" }}
                        align="center"
                        >
                            find your dream partner
                        </Typography>
                    </Grid>
                </motion.div>

                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 4, duration: 1 }}
                viewport={{ once: true }}
                style={{
                    height: "100%",
                    width: "100%"
                }}
                >
                    <Grid item xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <Typography
                        style={{ fontSize: resFontSize("19px", "16px", "13px"), color: mainColors.background, textAlign: "center" }}
                        sx={{ mb: "20px" }}
                        align="center"
                        >
                            Enter your email to be first on our new platform!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{mb:"10px"}}
                    >
                        <TextField
                        size="small"
                        fullWidth
                        sx={{ mb: "1vh", ".MuiInputBase-input": {
                            background: "#000",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: mainColors.background
                            },
                            "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                    borderColor: mainColors.background
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: mainColors.background
                                },
                            },
                            input: { color: mainColors.background, textAlign: "center" }
                        }}
                        onChange={handleChange}
                        onClick={() => {
                            setInvalidEmail(false)
                            setSuccess(false)
                        }}
                        value={interestedEmail}
                        />
                    </Grid>
                </motion.div>
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 5, duration: 1 }}
                viewport={{ once: true }}
                style={{
                    height: "100%",
                    width: "100%"
                }}
                >
                    <Grid item xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{mb:"10px"}}
                    >
                        {
                            loading ?
                            <LoadingComp
                            width="max(4.2vw, 55px)"
                            mainColor={mainColors.background}
                            secColor={mainColors.primary}
                            /> :
                            <KMButton endArrow
                            variant={"impTitle"}
                            text={"Register"}
                            onClick={handleSubmit}/>
                        }
                    </Grid>
                </motion.div>
                {
                    invalidEmail ?
                    (<Grid item xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                        <Typography
                        style={{ fontSize: resFontSize("17px", "15px", "13px"), color: mainColors.background, textAlign: "center" }}
                        sx={{ mb: "20px" }}
                        align="center"
                        >
                            Please enter a valid email
                        </Typography>
                    </Grid>) :
                    (<></>)
                }
                {
                    success ?
                    (
                        <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        style={{
                            height: "100%",
                            width: "100%"
                        }}
                        >
                            <Grid item xs={12}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            >
                                <Typography
                                style={{ fontSize: resFontSize("17px", "15px", "13px"), color: mainColors.background, textAlign: "center" }}
                                sx={{ mb: "20px" }}
                                align="center"
                                >
                                    Thank you for registering your interest!
                                </Typography>
                            </Grid>
                        </motion.div>
                    ) :
                    (<></>)
                }
            </Grid>
        </Grid>
    )
}

export default HeroInterest