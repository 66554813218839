import { Grid, Paper, Typography } from "@mui/material"
import { mainColors } from "../Theme/Colors/palette"
import desktopServiceTile1 from "../../img/desktopServiceTile1-min.jpg"
import desktopServiceTile2 from "../../img/desktopServiceTile2-min.jpg"
import desktopServiceTile3 from "../../img/desktopServiceTile3-min.jpg"
import { useContext } from "react"
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider"
import { useMediaQuery } from "@mui/material";
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from "../IndividualComponents/Image"
import 'swiper/css';
import 'swiper/css/pagination';
import homeText from "../../text/home.json"
import imageAltText from "../../text/imageAlt.json"

const ServiceTiles = () => {

    const { isLaptopLanding } = useContext(ResponsiveContext)
    const singleBreakpoint = useMediaQuery('(min-width:900px)')

    return (
        <Grid container
        display="flex"
        justifyContent="center"
        spacing={ singleBreakpoint ? 5 : 0}
        sx={{px: isLaptopLanding ? "100px" : singleBreakpoint ? "80px" : "70px", my:"15vh"}}
        >

            {   
                singleBreakpoint ?
                (<>
                    <Grid item xs={isLaptopLanding ? 4 : 6 }>
                    <Paper elevation={0}
                    style={{background: mainColors.primary}}
                    >
                        <Image
                            src={`${desktopServiceTile1}`}
                            alt={imageAltText.home.tile1}
                            fit="cover"
                            position="center"
                            height="55vh"
                            width="100%"
                        />

                        <Typography variant="h3"
                        align="center"
                        sx={{mt:"3vh", mb:"1.5vh"}}
                        >
                            {homeText.tile1.title}
                        </Typography>
                        <Typography variant="h5"
                        display="flex"
                        align="justify">
                        {homeText.tile1.text}
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={isLaptopLanding ? 4 : 6 }>
                <Paper elevation={0}
                style={{background: mainColors.primary}}
                >
                    <Image
                        src={`${desktopServiceTile2}`}
                        alt={imageAltText.home.tile2}
                        fit="cover"
                        position="center"
                        height="55vh"
                        width="100%"
                    />

                    <Typography variant="h3"
                    align="center"
                    sx={{mt:"3vh", mb:"1.5vh"}}
                    >
                        {homeText.tile2.title}
                    </Typography>
                    <Typography variant="h5"
                    display="flex"
                    align="justify">
                    {homeText.tile2.text}
                    </Typography>
                </Paper>
                </Grid>

                <Grid item xs={isLaptopLanding ? 4 : 6 }>
                <Paper elevation={0}
                style={{background: mainColors.primary}}
                >
                    <Image
                        src={`${desktopServiceTile3}`}
                        alt={imageAltText.home.tile3}
                        fit="cover"
                        position="center"
                        height="55vh"
                        width="100%"
                    />

                    <Typography variant="h3"
                    align="center"
                    sx={{mt:"3vh", mb:"1.5vh"}}
                    >
                        {homeText.tile3.title}
                    </Typography>
                    <Typography variant="h5"
                    display="flex"
                    align="justify">
                        {homeText.tile3.text}
                    </Typography>
                </Paper>
                </Grid>
                </>
            ) : (
                <Swiper
                spaceBetween={50}
                modules={[Pagination]}
                pagination={{ clickable: true }}
                >
                    <SwiperSlide>
                        <Paper elevation={0}
                        style={{background: mainColors.primary, paddingBottom: "50px"}}
                        >
                            <Image
                                src={`${desktopServiceTile1}`}
                                alt={imageAltText.home.tile1}
                                fit="cover"
                                position="center"
                                height="55vh"
                                width="100%"
                            />

                            <Typography variant="h3"
                            align="center"
                            sx={{mt:"3vh", mb:"1.5vh"}}
                            >
                                {homeText.tile1.title}
                            </Typography>
                            <Typography variant="h5"
                            display="flex"
                            align="justify">
                            {homeText.tile1.text}
                            </Typography>
                        </Paper>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Paper elevation={0}
                        style={{background: mainColors.primary, paddingBottom: "50px"}}
                        >

                            <Image
                                src={`${desktopServiceTile2}`}
                                alt={imageAltText.home.tile2}
                                fit="cover"
                                position="center"
                                height="55vh"
                                width="100%"
                            />

                            <Typography variant="h3"
                            align="center"
                            sx={{mt:"3vh", mb:"1.5vh"}}
                            >
                                {homeText.tile2.title}
                            </Typography>
                            <Typography variant="h5"
                            display="flex"
                            align="justify">
                            {homeText.tile2.text}
                            </Typography>
                        </Paper>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Paper elevation={0}
                        style={{background: mainColors.primary, paddingBottom: "50px"}}
                        >

                            <Image
                                src={`${desktopServiceTile3}`}
                                alt={imageAltText.home.tile3}
                                fit="cover"
                                position="center"
                                height="55vh"
                                width="100%"
                            />

                            <Typography variant="h3"
                            align="center"
                            sx={{mt:"3vh", mb:"1.5vh"}}
                            >
                                {homeText.tile3.title}
                            </Typography>
                            <Typography variant="h5"
                            display="flex"
                            align="justify">
                                {homeText.tile3.text}
                            </Typography>
                        </Paper>
                    </SwiperSlide>
                </Swiper>
            )
        }
        </Grid>
    )
}

export default ServiceTiles