import { Grid, Typography } from "@mui/material"
import MainBody from "../Layout/MainBody"
import ProfilePicDragDropComp from "../components/CombinedComponents/ProfilePicDragDropComp"
import DragAndDropComp from "../components/CombinedComponents/DragAndDropComp"
import { useEffect, useState } from "react"
import { homePage } from "./URLs"
import { useNavigate } from "react-router-dom"
import useAccessTokenInfo from "../hooks/useAccessTokenInfo"
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const Gallery = () => {
    const navigate = useNavigate()
    const info = useAccessTokenInfo()
    const isReadyForMatches = info?.readyForMatches || false
    useEffect(() => {
        if (!isReadyForMatches) {
            navigate(homePage)
        }
    }, [])
    
    const [profilePic, setProfilePic] = useState([]);
    const [otherPics, setOtherPics] = useState([]);

    return (
        <MainBody>
        <SEO
            title={seoText.default.title}
            description={seoText.default.description}
        />
        <Grid container
        sx={{px:2}}
        >
            <Grid item xs={12}
            sx={{ml:2, mt: 5}}
            >
                <Typography
                variant="h5"
                align="center"
                >
                    Profile picture
                </Typography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
                <ProfilePicDragDropComp images={profilePic} setImages={setProfilePic} />
            </Grid>
            <Grid item xs={12}
            sx={{ml:2, mt: 5}}
            >
                <Typography
                variant="h5"
                align="center"
                >
                    Favourite pictures
                </Typography>
            </Grid>
            <Grid item xs={12}
            >
                <DragAndDropComp images={otherPics} setImages={setOtherPics} />
            </Grid>
        </Grid>
        </MainBody>
    )
}

export default Gallery