import { useState, useEffect, useContext } from "react";
import { RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from "@mui/material";
import StructureContext from "../../../StructureDecoder/context/StructureProvider";
import { purple, red } from "@mui/material/colors";
import { mainColors } from "../../Theme/Colors/palette";

const CPRadioComp = (props) => {

    const { page, setPage, required, setRequired } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])
    const discreteVals = thisPage[path[1]][path[2]].discreteVals

    const handleFocus = () => {
        if (required.includes(path[2])) {
            const index = required.indexOf(path[2]);
            required.splice(index, 1);
            setRequired(required)
        }
    }

    return (
        <FormControl
        >
            <FormLabel></FormLabel>
            <RadioGroup
                value={thisPage[path[1]][path[2]].value}
                onChange={(e) => {
                    thisPage[path[1]][path[2]].value = e.target.value
                    setPage(path[0], thisPage)
                }}
                row  
                sx={{mt:2, px:1}}
                onFocus={handleFocus}
            >
                {discreteVals.map((discreteVal) => {
                    return <FormControlLabel 
                        key={discreteVal} 
                        value={discreteVal}
                        control={
                        <Radio
                        color="secondary"
                        sx={{
                            color: required.includes(path[2]) ? red[500] : ""
                        }}
                        />
                        }
                        label={discreteVal} />
                })}
            </RadioGroup>
        </FormControl>
    )
}

export default CPRadioComp