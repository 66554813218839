import { useState, createContext, useContext } from "react";
import ResponsiveContext from "../ResponsiveDesign/ResponsiveProvider";

const LayoutContext = createContext({});

export const LayoutProvider = ({ children }) => {

    const [currPage, setCurrPage] = useState("home");
    const { isTablet, isLaptop } = useContext(ResponsiveContext)

    // ? complete profile progress
    const [progress, setProgress] = useState(0);

    const mainBodyWidth = isLaptop ? "max(50vw, 600px)"
                            : isTablet ? "max(60vw, 400px)"
                                : "100vw"
    const rightContentWidth = "max(20vw, 380px)"
    const rightContentPaddingX = "40px"
    const navBarWidth = "max(4vw, 60px)"
    const navPicWidth = isLaptop ? "max(15vw, 200px)"
                            : isTablet ? navBarWidth
                                : "0px"
    const originalNavPicWidth = "max(15vw, 200px)"

    // ? viewport height percentage
    const rightContentPaddingTop = 2
    const cardHeight = 55
    const rightContentInfoHeight = 30
    const rightContentSpaceBetween = 2
    const rightContentPaddingBottom = 1.5

    const pageFadeDuration = 500

    return (
        <LayoutContext.Provider value={{ navPicWidth,
        mainBodyWidth,
        rightContentWidth,
        currPage,
        setCurrPage,
        rightContentPaddingX,
        rightContentPaddingTop,
        cardHeight,
        rightContentInfoHeight,
        rightContentSpaceBetween,
        rightContentPaddingBottom,
        navBarWidth,
        pageFadeDuration,
        progress,
        setProgress,
        originalNavPicWidth
        }}>
            {children}
        </LayoutContext.Provider>
    )
}

export default LayoutContext;
