import { useState, useEffect, useContext } from "react";
import { Slider, Stack } from "@mui/material";
import HeightIcon from '@mui/icons-material/Height';
import StructureContext from "../../../StructureDecoder/context/StructureProvider";

const CPRangeSliderComp = (props) => {

    const { page, setPage } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])
    const discreteVals = thisPage[path[1]][path[2]].discreteVals
    const localProps = thisPage[path[1]][path[2]]

    return (
        <Stack spacing={1} direction="row" sx={{ mt: "21px", px:1}} alignItems="center">
            <HeightIcon fontSize="small"/>
            <Slider 
            valueLabelDisplay="auto" 
            min={discreteVals[0]}
            max={discreteVals[1]}
            step={localProps.increment}
            value={(localProps.value.length === 0) ? localProps.defaultVals : localProps.value}
            disableSwap
            color="secondary"
            onChange={(e) => {
                thisPage[path[1]][path[2]].value = e.target.value
                setPage(path[0], thisPage)
            }}
            />
            <HeightIcon fontSize="large"/>
        </Stack>
    )
}

export default CPRangeSliderComp