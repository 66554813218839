import { saveAs } from "file-saver"
import axios from "../api/axios"
import useAccessTokenInfo from "../hooks/useAccessTokenInfo"
import { CREATE_PORTAL_URL, CREATE_SETTINGS_URL, DELETE_ALL_URL, DeleteProfileSuccessfulPage, GET_ALL_INFO_URL, GET_PICS_URL, GET_SETTINGS_URL, landingPage } from "./URLs"
import JSZip from "jszip"
import jsesc from "jsesc"
import MainBody from "../Layout/MainBody"
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Switch, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { mainColors } from "../components/Theme/Colors/palette"
import { useNavigate } from "react-router-dom"
import AuthContext from "../auth/context/AuthProvider"
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const notifSettingsTranslation = {
    "Send me all updates": "all",
    "I want to receive personalised updates": "personalised",
    "Only email me when necessary": "necessary"
}

const Settings = () => {
    const info = useAccessTokenInfo()
    const user = info?.user || ""

    const [notifSetting, setNotifSetting] = useState("all");
    const [dispName, setDispName] = useState(true);
    const [dispPic, setDispPic] = useState(true);
    const [status, setStatus] = useState("");

    useEffect(() => {
        if(!info.settingsCreated) {
            axios.post(CREATE_SETTINGS_URL,
                JSON.stringify({ Settings: {...settingsObject }, email: user }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_CREATE_SETTINGS_KEY}` },
                    withCredentials: true
                }
            ).then(res => {
                if (res?.data?.Settings) {
                    const settings = res.data.Settings
                }
            })
        }
    }, [])

    useEffect(() => {
        axios.post(GET_SETTINGS_URL, 
            JSON.stringify({ user }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_GET_SETTINGS_KEY}` },
                withCredentials: true
            }
        ).then(res => {
            if (res?.data?.Settings) {
                const settings = res.data.Settings
                setDispName(settings.dispName)
                setDispPic(settings.dispPic)
                setNotifSetting(settings.notifPref)
            }
        })
    }, [])

    const navigate = useNavigate()
    const { setAuth } = useContext(AuthContext)

    const manageSubscription = async () => {
        await axios.post(CREATE_PORTAL_URL, 
            JSON.stringify({ url: process.env.REACT_APP_MY_URL, user: user }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_CREATE_PORTAL_SESSION}` },
                withCredentials: true
            }
        ).then(res => {
            window.location.href = res.data.url
        })
    }

    const getAllInfo = async () => {

        const zip = new JSZip()
        const img = zip.folder("images")

        var promisesArr = []
        promisesArr.push(
            axios.post(GET_ALL_INFO_URL, 
                JSON.stringify({ user: user }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_GET_ALL_INFO_KEY}` },
                    withCredentials: true
                }
            )
        )
   
        for (let i = 0; i < 5; i++) {
            promisesArr.push(
                axios.post(GET_PICS_URL, 
                    JSON.stringify({
                        email: user,
                        picKey: `${i}`
                    }),
                    {
                        headers: { 'Content-Type': 'application/json',
                        'x-functions-key': `${process.env.REACT_APP_GET_PICS_KEY}` },
                        withCredentials: true
                    }
                )
            ) 
        }

        promisesArr.push(
            axios.post(GET_PICS_URL, 
            JSON.stringify({
                email: user,
                picKey: `profilePic`
            }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_GET_PICS_KEY}` },
                withCredentials: true
            }
            )
        )

        const convertBase64ToFile = (base64String, fileName) => {
            let arr = base64String.split(',');
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let uint8Array = new Uint8Array(n);
            while (n--) {
               uint8Array[n] = bstr.charCodeAt(n);
            }
            let file = new File([uint8Array], fileName, { type: mime });
            return file;
       }

        Promise.all(promisesArr).then(res => {
            zip.file("UserData.txt", jsesc(JSON.stringify(res[0].data)));
            for (let i = 0; i < 5; i++) {
                if (res[i+1].data != "None") {
                    let file = convertBase64ToFile(res[i+1].data.src, `${i}`);
                    img.file(`${i}`, file)
                }
            }
            if (res[6].data != "None") {
                let file = convertBase64ToFile(res[6].data.src, `profilePic`);
                img.file("profilePic", file)
            }
            zip.generateAsync({type:"blob"}).then(function(content) {
                // see FileSaver.js
                saveAs(content, "Data.zip");
            });
        })
    }

    const deleteAllInfo = async () => {
        axios.post(DELETE_ALL_URL, 
            JSON.stringify({ user }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_DELETE_ALL_INFO}` },
                withCredentials: true
            }
        ).then(res => {
            if (res?.data === "Done") {
                setAuth("")
                navigate(DeleteProfileSuccessfulPage)
            } else {
                console.log(res)
            }
        })
    }

    const dangerZone = [
        {
            label: "Delete my account",
            buttonText: "Delete",
            func: () => deleteAllInfo()
        },
        {
            label: "Download my data",
            buttonText: "Download",
            func: () => getAllInfo()
        },
        {
            label: "Status",
            buttonText: "Update",
            func: () => null,
            disabled: true
        },
    ]

    const settingsObject = {
        dispName,
        dispPic,
        notifPref: notifSetting,
        status
    }

    return (
        <MainBody>
            <SEO
                title={seoText.settings.title}
                description={seoText.settings.description}
            />
            <Grid container
            sx={{
                pt: "5vh",
                px: "2vw"
            }}
            width="100%"
            >
                <Grid item xs={12}>
                    <Typography
                    variant="h3"
                    >
                        Settings
                    </Typography>
                </Grid>

                <Grid item xs={12}
                sx={{
                    mt: "3vh"
                }}
                >
                    <Typography
                    variant="h4"
                    >
                        Personal
                    </Typography>
                </Grid>

                <Grid item xs={12}
                sx={{
                    mt: "1.2vh"
                }}
                >
                    <FormControlLabel control={<Switch color="secondary" />} 
                    label={
                        <Typography
                        variant="h5"
                        sx={{
                            ml:"1.5vw"
                        }}
                        >
                            Display my name
                        </Typography>
                    }
                    checked={dispName}
                    onChange={() => {
                        setDispName(!dispName)
                        axios.post(CREATE_SETTINGS_URL, 
                            JSON.stringify({ Settings: {...settingsObject, dispName: !dispName }, email: user }),
                            {
                                headers: { 'Content-Type': 'application/json',
                                'x-functions-key': `${process.env.REACT_APP_CREATE_SETTINGS_KEY}` },
                                withCredentials: true
                            }
                        )
                    }}
                    />
                </Grid>

                <Grid item xs={12}
                sx={{
                    mt: "1.2vh"
                }}
                >
                    <FormControlLabel control={<Switch color="secondary" />} 
                    label={
                        <Typography
                        variant="h5"
                        sx={{
                            ml:"1.5vw"
                        }}
                        >
                            Display my profile picture
                        </Typography>
                    }
                    checked={dispPic}
                    onChange={() => {
                        setDispPic(!dispPic)
                        axios.post(CREATE_SETTINGS_URL, 
                            JSON.stringify({ Settings: {...settingsObject, dispPic: !dispPic}, email: user }),
                            {
                                headers: { 'Content-Type': 'application/json',
                                'x-functions-key': `${process.env.REACT_APP_CREATE_SETTINGS_KEY}` },
                                withCredentials: true
                            }
                        )
                    }}
                    />
                </Grid>

                <Grid item xs={12}
                sx={{
                    mt: "3vh"
                }}
                >
                    <Typography
                    variant="h4"
                    >
                        Notifications
                    </Typography>
                </Grid>

                <Grid item xs={12}
                sx={{
                    mt: "1.2vh"
                }}
                >
                    <RadioGroup
                    value={notifSetting}
                    onChange={(e) => {
                        setNotifSetting(e.target.value)
                        axios.post(CREATE_SETTINGS_URL, 
                            JSON.stringify({ Settings: {...settingsObject, notifPref: e.target.value}, email: user }),
                            {
                                headers: { 'Content-Type': 'application/json',
                                'x-functions-key': `${process.env.REACT_APP_CREATE_SETTINGS_KEY}` },
                                withCredentials: true
                            }
                        )
                    }}
                    >
                        {
                            Object.keys(notifSettingsTranslation).map((setting,index) => {
                                return <FormControlLabel value={notifSettingsTranslation[setting]} control={<Radio color="secondary" />} 
                                label={
                                    <Typography
                                    variant="h5"
                                    sx={{
                                        ml:"1vw"
                                    }}
                                    >
                                        {setting}
                                    </Typography>
                                }
                                key={index}
                                />
                            })
                        }
                    </RadioGroup>
                </Grid>

                <Grid item xs={12}
                sx={{
                    mt: "3vh"
                }}
                >
                    <Typography
                    variant="h4"
                    >
                        Subscriptions
                    </Typography>
                </Grid>

                <Grid item xs={12}
                sx={{
                    mt: "1.2vh"
                }}
                >
                    <Button
                    style={{
                        background: process.env.REACT_APP_SUBSCRIPTION_ENABLED === "true" ? mainColors.important : mainColors.grey
                    }}
                    sx={{
                        px:3
                    }}
                    onClick={process.env.REACT_APP_SUBSCRIPTION_ENABLED === "true" ? () => manageSubscription() : () => null}
                    >
                        <Typography
                        variant="h5"
                        style={{
                            color: process.env.REACT_APP_SUBSCRIPTION_ENABLED === "true" ? "white" : "black"
                        }}
                        >
                            Manage My Subscription
                        </Typography>
                    </Button>
                </Grid>

                <Grid item xs={12}
                sx={{
                    mt: "3vh"
                }}
                >
                    <Typography
                    variant="h4"
                    >
                        Danger Zone
                    </Typography>
                </Grid>

                <Grid item xs={12}
                sx={{
                    mt: "1.2vh"
                }}
                style={{
                    border: "2px solid red",
                    borderRadius: 20,
                    backgroundColor: "rgba(255, 0, 0, 0.25)"
                }}
                >
                    {
                        dangerZone.map(props => {
                            return (
                                <Grid container
                                direction="row"
                                style={{
                                    width: "100%",
                                }}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    py:"1.2vh",
                                    px:"1.5vw"
                                }}
                                >
                                    <Grid item
                                    >
                                        <Typography
                                        variant="h5"
                                        >
                                            {props.label}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                        style={{
                                            background: props?.disabled ? "grey" : mainColors.textColor
                                        }}
                                        sx={{
                                            px:3
                                        }}
                                        onClick={props.func}
                                        disabled={props?.disabled}
                                        >
                                            <Typography
                                            variant="h5"
                                            style={{
                                                // color: "rgba(255, 255, 255, 0.5)"
                                                color: props?.disabled ? "black" : "red"
                                            }}
                                            >
                                                {props.buttonText}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </MainBody>
    )
}

export default Settings

//octet-stream
//x-zip-compressed