import { Avatar, Button, Grid, Typography } from "@mui/material";
import { mainColors } from "../Theme/Colors/palette";
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo";
import { GET_PICS_URL } from "../../pages/URLs";
import axios from "../../api/axios";
import { useEffect, useState } from "react";
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from "react-router-dom";

const UserFeedCard = ({ user, name, age, height, email, likeOrDislike }) => {
    const accessTokenInfo = useAccessTokenInfo()
    const avatar = accessTokenInfo?.dispName || accessTokenInfo?.user || accessTokenInfo?.email || "NA"
    const navigate = useNavigate();

    const [avatarImage, setAvatarImage] = useState("")

    useEffect(() => {
        axios.post(GET_PICS_URL, 
            JSON.stringify({ email: user, picKey: "profilePic" }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_GET_PICS_KEY}` },
                withCredentials: true
            }
        ).then(res => {
            setAvatarImage(res.data.src)
        })
    }, [avatar])

    return (
        <div style={{
            outlineColor: "transparent",
            background: mainColors.primary,
            borderRadius: 20,
            width: "100%"
        }}>
            <Grid container direction="row">
                <Grid item display={"flex"} alignItems={"center"} justifyContent={"center"}
                sx={{ mx: 4, my: 3 }}>
                    <Avatar style={{height:"80px", width:"80px", background: mainColors.accent}}
                    src={avatarImage || ""}/>
                </Grid>
                <Grid item display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Grid container direction="column" sx={{ my: 2 }}>
                        <Grid item>
                            <Typography variant="h5">
                                {name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                Age: {age}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                Height: {height}m
                            </Typography>
                        </Grid>
                        { email && <Grid item>
                            <Typography variant="h6">
                                Email: {email}
                            </Typography>
                        </Grid> }
                    </Grid>
                </Grid>
                <Grid item xs={true}>
                    { likeOrDislike && <Grid item sx={{ pb: 2, pr: 2 }}
                    style={{ display: "flex", alignItems: "end", height: "100%", justifyContent: "end" }}>
                        <Button color="textColor" variant="contained" sx={{ py: 1, mr: 1 }} disabled
                        style={{ borderWidth: 0, backgroundColor: likeOrDislike === "1" ? mainColors.darkpink : mainColors.blue }}>
                            <Typography style={{ fontFamily: "Quicksand", color: "white", display: "block", whiteSpace: "nowrap" }}>
                                {likeOrDislike === "1" ? "Liked" : "Not liked"}
                            </Typography>
                        </Button>
                        <Button color="accent" variant="contained" sx={{ py: 1 }}
                        style={{ borderWidth: 0 }} endIcon={<EastIcon/>}
                        onClick={() => navigate(`/revisitProfile/${user}/${likeOrDislike}`)}>
                            <Typography style={{ fontFamily: "Quicksand", color: "white" }}>
                                Revisit
                            </Typography>
                        </Button>
                    </Grid> }
                </Grid>
            </Grid>
        </div>
    )
}

export default UserFeedCard