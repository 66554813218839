import { Grid, IconButton } from "@mui/material"
import { useContext } from "react"
import LayoutContext from "../../../Layout/LayoutProvider"
import NavBarContext from "../Context/NavBarProvider"
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

const Base = ({ children }) => {

    const { navBarWidth } = useContext(LayoutContext)
    const { toggleNavBar, paddingTop, navToggleDisabled } = useContext(NavBarContext)

    return (
        <div style={{
            height: navBarWidth,
            position: "fixed",
            left: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "end",
            zIndex: 99
        }}>
            <Grid container
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{width: "100vw"}}
            sx={{bgcolor: "rgba(255, 215, 111, 0.8)"}}>
                <Grid item
                sx={{mr:`${paddingTop}px`}}
                style={{ width: "100%" }}
                display="flex"
                justifyContent="end">
                        <IconButton onClick={toggleNavBar}
                        disabled={navToggleDisabled}>
                            <KeyboardDoubleArrowUpIcon
                            style={{height: "40px", width: "40px", fill: "black"}}/>
                        </IconButton>
                </Grid>

                {children}
            </Grid>
        </div>
    )
}

export default Base