import { useEffect, useState } from "react"
import axios from "../api/axios"
import { FULL_MATCHING_URL } from "../pages/URLs"

const useFullMatching = (user) => {

    const [res, setRes] = useState(null)

    useEffect(() => {

        const getMatchingData = async (username) => {
            await axios.post(FULL_MATCHING_URL, 
                JSON.stringify({ user: username }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_FULL_MATCHING_KEY}` },
                    withCredentials: true
                }
            ).then(setRes)
        }
        getMatchingData(user)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return res
}

export default useFullMatching