import { Grid, Typography } from "@mui/material"
import { mainColors } from "../Theme/Colors/palette"

const MobileInitialActionCenter = ({icon, text, func}) => {
    return (
        <div
        onClick={func}
        style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            paddingLeft: "2vw",
            paddingRight: "2vw",
            paddingTop: "2vh"
        }}
        >
        <Grid container
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: `3px solid ${mainColors.primary}`,
            background: "white",
            borderRadius: 20
        }}
        sx={{px: "2vw", py: "2vh"}}
        >
            <Grid items style={{display: "flex", alignItems: "center"}} sx={{mx: 2}}>
                {icon}
            </Grid>
            <Grid item xs={true}>
                <Typography variant="h5"
                align="center">
                    {text}
                </Typography>
            </Grid>
        </Grid>
        </div>
    )
}

export default MobileInitialActionCenter