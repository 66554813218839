import { Button, Typography } from "@mui/material"
import EastIcon from '@mui/icons-material/East';
import LoadingComp from "../../IndividualComponents/LoadingComp";
import { mainColors } from "../../Theme/Colors/palette";
import React from "react";

const NextButton = ({ text, func, loading=false }) => {

    return (
        <Button
        variant="contained"
        color="important"
        onClick={ func }
        endIcon={ <EastIcon/> }
        sx={{px:"20px"}}
        disabled={loading}
        >
            {loading 
                ? (
                    <LoadingComp
                    width="2vw"
                    mainColor={mainColors.important}
                    secColor={mainColors.pink}
                    />
                    )
                    : <React.Fragment key="empty"></React.Fragment>
            }
            <Typography variant="body1"
            sx={{ ml: loading ? "1vw" : "0vw" }}
            >
            {text}
            </Typography>
        </Button>
    )
}

export default NextButton