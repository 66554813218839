import { Grid } from "@mui/material";

const Image = (props) => {
  return (
    <Grid container
    style={{
      objectPosition: props.position,
      objectFit: props.fit,
      height: props.height,
      width: props.width
    }}
    >
      <img 
        alt={props.alt} 
        src={props.src} 
        style={{
          objectFit: props.fit,
          objectPosition: props.position,
          height: props.height,
          width: props.width
        }}
      />
    </Grid>
  );
}
export default Image