// UnverifiedIcon.js
import React from 'react';

const UnverifiedIcon = () => (
  // <?xml version="1.0" encoding="utf-8"?>
  <svg version="1.1"
    id="unverifiedIcon"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="50"
    height="50"
    viewBox="0 0 1080 1080"
    xmlSpace="preserve"
  >
  <g>
    <path fill="#808080" d="M540.66,226.27c13.03,21.82,26.38,45.46,40.97,68.32c28.12,44.05,60.47,85.8,84.57,131.92 c30.08,57.57,26.88,119.46,3.72,179.31c-22.35,57.78-76.14,90.39-138.02,86.71c-65.03-3.86-113.48-42.7-130.52-104.84 c-19.58-71.38-10.9-138.64,30.47-200.61C467.31,333.95,503.41,281.26,540.66,226.27z"/>
    <path fill="#4D4D4D" d="M997.33,712.05c-57.96,21.12-112.35,42.96-168.09,60.44c-30.37,9.52-62.9,14.31-94.75,16.12 c-19.04,1.08-38.83-7.03-57.94-12.25c-20.4-5.57-33.46-17.71-40.05-39.71c-4.22-14.1-17.13-25.59-27.91-40.73 c53.36-3.87,95.69-33.17,140.12-58.5c28.17-16.06,59.43-11.95,89.93-4.83C897.9,646.43,948.98,676.08,997.33,712.05z"/>
    <path fill="#4D4D4D" d="M466.44,695.17c-11.14,21.09-20.75,41.45-32.54,60.47c-4.39,7.07-13.2,12.41-21.12,16.09 c-55.27,25.68-110.45,21.73-166.63,1.03c-51.1-18.83-103.33-34.58-155.05-51.73c-2.95-0.98-5.79-2.31-8.43-3.37 c0.36-2.86-0.04-5.16,0.82-5.84c58.88-46.79,123.44-81.18,200.1-84.43c17.25-0.73,37.01,4.78,51.99,13.6 C377.72,665.78,418.72,692.06,466.44,695.17z"/>
    <path fill="#808080" d="M203.64,336.07c75.69-2.84,142.89,19.83,205.72,60.54c-43.73,97.67-44.83,189.56,29.24,277.82 c-44.33-7.78-76.5-28.06-103.66-56.26c-31.66-32.86-45.62-74.39-53.67-118.5C270.16,438.83,248.75,382.78,203.64,336.07z"/>
    <path fill="#808080" d="M882.7,333.33c-50.66,46.24-71.54,99.55-84.49,157.09c-5.82,25.85-11.24,52.36-21.64,76.48 c-24.99,57.94-70.67,92.08-135.07,107.99c2.5-5.73,3.09-8.74,4.82-10.79c63.21-75.07,67.23-158.03,30.76-245.19 c-7.42-17.74-5.8-25.89,11.17-35.14c51.03-27.79,104.75-45.71,162.71-50.36C858.67,332.8,866.48,333.33,882.7,333.33z"/>
    <path fill="#4D4D4D" d="M539.02,871.28c-23.09-19.74-46.12-38.47-68.06-58.41c-25.07-22.78-31.55-48.22-20.47-73.51 c11.71-26.73,32.27-37.62,66.32-36.15c15.7,0.68,31.63,1.38,47.16-0.45c28.43-3.35,48.74,8.44,61.95,32.08 c12.44,22.25,6.46,53.94-14.14,73.34C588.4,830.2,563.28,850.38,539.02,871.28z"/>
    <path fill="#808080" d="M104.16,473.71c45.69-17.85,96-21.13,146.99-8.09c6.28,1.61,12.01,12.75,14.53,20.59 c13.51,42.12,25.92,84.59,38.19,125.22c-26.28,4.22-58.38,10.47-90.76,13.69c-6.74,0.67-17.36-8.28-21.42-15.5 c-12.18-21.66-19.91-46.02-33.3-66.78C143.02,519.05,123.47,497.96,104.16,473.71z"/>
    <path fill="#808080" d="M774.12,612.38c13.04-42.92,25.52-85.44,39.15-127.6c2.43-7.53,8.44-17.95,14.69-19.54 c47.72-12.16,95.26-9.07,141.93,6.59c2.86,0.96,5.1,3.74,9.21,6.87c-49.04,26.09-65.5,74.35-86.67,119.05 c-14.75,31.15-14.83,30.65-48.51,23.73C819.19,616.4,793.77,614.83,774.12,612.38z"/>
    <path fill="#808080" d="M329.84,209.89c56.26,14.99,99.18,44.87,135.37,84.88c3.82,4.23,1.85,14.54,1,21.85 c-0.42,3.64-4.32,6.91-6.75,10.27c-45.8,63.34-46.04,63.71-112.74,21.49c-5.76-3.64-8.67-16.77-7.9-25.02 C344.55,262.03,344.73,258.89,329.84,209.89z"/>
    <path fill="#808080" d="M751.69,208.73c-22.03,41.56-18.14,78.99-11.87,117.15c1.21,7.36-5.87,19.03-12.59,24.08 c-11.05,8.3-26.64,10.69-37.27,19.34c-23.48,19.09-37.68,10.68-49.82-12.34c-3.46-6.56-8.31-12.41-12.71-18.46 c-25.98-35.69-26.03-35.72,6.01-64.9C665.75,244.17,702.24,222.34,751.69,208.73z"/>
  </g>
  </svg>
);

export default UnverifiedIcon;