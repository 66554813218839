import { useContext } from "react"
import Base from "./Base"
import CollapseView from "./CollapseView"
import NavBarContext from "../Context/NavBarProvider"
import FullView from "./FullView"

const TabletNavBar = () => {
    const { switchMenu } = useContext(NavBarContext)

    return (
        <Base>
            { switchMenu ? <FullView/> : <CollapseView/> }
        </Base>
    )
}

export default TabletNavBar