import { Grid, Alert, Typography, Checkbox } from "@mui/material"
import NextButton from "../ProfileDecoder/CompleteProfileIndividualComps/NextButton"
import PrevButton from "../ProfileDecoder/CompleteProfileIndividualComps/PrevButton"
import { ABOUT_ME_INFO_URL, CREATE_REFERENCES_URL, homePage, PARTNER_PREFS_INFO_URL, PERSONAL_INFO_URL, SPIRITUAL_INFO_URL, termsAndConditionsPage } from "../../pages/URLs";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { useContext, useEffect, useState } from "react";
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo";
import useRefreshToken from "../../hooks/useRefreshToken";
import ProfilePicDragDropComp from "./ProfilePicDragDropComp";
import DragAndDropComp from "./DragAndDropComp";
import { numOfPages } from "../ProfileDecoder/structure";
import StructureContext from "../../StructureDecoder/context/StructureProvider";
import { mainColors } from "../Theme/Colors/palette";

const apiURLS = [PERSONAL_INFO_URL, ABOUT_ME_INFO_URL, SPIRITUAL_INFO_URL, PARTNER_PREFS_INFO_URL, CREATE_REFERENCES_URL]
const apiKeys = [process.env.REACT_APP_CREATE_PERSONAL_INFO_KEY, process.env.REACT_APP_CREATE_ABOUT_ME_INFO_KEY, process.env.REACT_APP_CREATE_SPIRITUAL_INFO_KEY, process.env.REACT_APP_CREATE_PARTNER_PREF_INFO_KEY, process.env.REACT_APP_REFERENCES_KEY]

const PageButtons = (props) => {

    const index = props.index
    const updateIndex = props.updateIndex
    const setProgress = props.setProgress
    const { page, setRequired, required } = useContext(StructureContext)

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [check, setCheck] = useState(false);
    const [checkErr, setCheckErr] = useState(false);
    useEffect(() => {
        if (openSnackbar && required.length === 0) {
            setOpenSnackbar(false)
            return
        }
        
    },[required.length])

    // update progress
    useEffect(() => {
        var totalTime = 0
        var timeToPage = 0
        for (let i=0; i<numOfPages; i++) {
            const thisPage = page(`${i+1}`)
            const sections = Object.keys(thisPage)
            sections.forEach(section => {
                totalTime += thisPage[section].completionTime
                if (i<index) {
                    timeToPage += thisPage[section].completionTime
                }
            })
        }
        setProgress(Math.floor(timeToPage/totalTime * 100))
    }, [index])
    
    //for image uploads
    const [profilePic, setProfilePic] = useState([]);
    const [otherPics, setOtherPics] = useState([]);

    const refresh = useRefreshToken()
    const accessTokenEmail = useAccessTokenInfo("email")

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const thisPage = page(index+1)

    const pagePosition = (index === 0) ? "first"
                        : (index === numOfPages-1) ? "last"
                            : "middle"

    const getVals = () => {
        var valuesObject = {}
        const sections = Object.keys(thisPage)
        sections.forEach((section) => {
            valuesObject[section] = {}
            const fields = Object.keys(thisPage[section])
            fields.forEach((field) => {
                if (field === "completionTime" || field === "empty") {
                    return
                } else {
                    valuesObject[section][field] = thisPage[section][field].value
                }
            })
        })
        return valuesObject
    }

    const checkRequired = () => {
        var requiredFields = []
        const sections = Object.keys(thisPage)
        sections.forEach((section) => {
            const fields = Object.keys(thisPage[section])
            fields.forEach((field) => {
                if (field === "completionTime" || field === "empty") {
                    return
                } else {
                    const fieldProps = thisPage[section][field]
                    let dateEmptyCondition = false;
                    if (fieldProps.component === "date") {
                        const [d, m, y] = fieldProps.value.split("/");
                        if (!d || !m || !y) {
                            dateEmptyCondition = true;
                        }
                    }
                    const emptyCondition = fieldProps.value === "" || fieldProps.value.length === 0 || dateEmptyCondition;
                    if (fieldProps.label.slice(-1) === "*" && emptyCondition) {
                        requiredFields.push(field)
                    }
                }
            })
        })
        return requiredFields
    }

    const nextFunc = async () => {

        const requiredFields = checkRequired()
        if (requiredFields.length > 0) {
            setRequired(requiredFields)
            setOpenSnackbar(true)
            return
        }
        setLoading(true)
        var sectionPost = getVals()
        
        var finalPost = {email: accessTokenEmail,...sectionPost}
        try {
            await axios.post(apiURLS[index], 
                JSON.stringify(finalPost),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${apiKeys[index]}` },
                    withCredentials: true
                }
                )
                setTimeout(() => {
                    updateIndex(index+1)
                    setLoading(false)
                }, 1500)
            } catch (err) {
                setTimeout(() => {
                    console.log(err.response)
                    setLoading(false)
                },1500)
            }

    }

    const backFunc = () => {
        setOpenSnackbar(false)
        if (index === 0) {
            navigate(homePage)
        }
        updateIndex(index-1)
    }

    const submitFunc = async () => {
        if (!check) {
            setCheckErr(true)
            return
        }
        setLoading(true)
        // ! get references post working!!
        const sectionPost = getVals()
        try {
            await axios.post(apiURLS[index], 
                JSON.stringify({email: accessTokenEmail,...sectionPost}),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${apiKeys[index]}` },
                    withCredentials: true
                }
                )
            await refresh()
            setTimeout(() => {
                setLoading(false)
                navigate(homePage)
            },1500)
        } catch (err) {
            setTimeout(() => {
                console.log(err.response)
                setLoading(false)
            },1500)
        }
        setTimeout(() => {
            setLoading(false)
            navigate(homePage)
        },1500)

    }

    const saveExitFunc = async () => {

        const sectionPost = getVals()

        try {
            await axios.post(apiURLS[index], 
                JSON.stringify({email: accessTokenEmail,...sectionPost}),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${apiKeys[index]}` },
                    withCredentials: true
                }
                )
            navigate(homePage)
        } catch (err) {
            console.log(err.response)
        }
    }

    return (

        <>
        {(index === 1) 
        ? (
            <Grid container
            sx={{px:2}}
            >
                <Grid item xs={12}
                sx={{ml:2}}
                >
                    <Typography
                    variant="h5"
                    >
                        Upload a picture of yourself!
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <ProfilePicDragDropComp images={profilePic} setImages={setProfilePic} />
                </Grid>
                <Grid item xs={12}
                sx={{ml:2}}
                >
                    <Typography
                    variant="h5"
                    >
                        Your favourite pictures go here
                    </Typography>
                </Grid>
                <Grid item xs={12}
                >
                    <DragAndDropComp images={otherPics} setImages={setOtherPics} />
                </Grid>
            </Grid>
        )
        : <></>
        }
        {(index === 4) 
        ? (
            <Grid container
            sx={{px:2}}
            >
                <Grid item
                display={"flex"}
                alignItems="center"
                >
                    <Checkbox
                    onClick={() => {
                        setCheckErr(false)
                        setCheck(!check)
                    }}
                    checked={check}
                    color={"accent"}
                    />
                    <Typography variant="h6"
                    color={checkErr ? "red" : ""}
                    >
                        {"I accept the"}
                    </Typography>
                    <pre>
                        {" "}
                    </pre>
                    <div style={{
                        fontSize: "2vh",
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: checkErr ? "red" : ""
                    }}
                    onClick={() => navigate(termsAndConditionsPage)}
                    >
                        {"Terms and Conditions"}
                    </div>
                </Grid>
            </Grid>
        )
        : <></>
        }
        <Grid container direction="column"
        sx={{px:"1.2vw", pt:2, mb: 12}}
        >
            <Grid item xs={12}>
                <Grid container direction="row" 
                alignItems="center"
                justifyContent="space-between"
                >
                    <Grid item xs="auto">
                        <PrevButton
                        text={"Back"}
                        func={backFunc}
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <NextButton
                        text={(pagePosition === "last") ? "Submit" : "Save & Continue"}
                        func={(pagePosition === "last") ? submitFunc : nextFunc}
                        loading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <PrevButton
                text={"Save & Exit"}
                func={saveExitFunc}
                />
            </Grid>
        </Grid>
        {/* <Snackbar
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={(e, reason) => {
                if (reason === "clickaway") {
                    return
                }
                setOpenSnackbar(false)
            }}
            variant="contained"
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            sx={{
                "& MuiSnackbar-root": {
                    bottom: "30vh",
                    left: "20vw"
                }
            }}
            > */}
            {/* <Snackbar
            open={true}
            >
                <Alert severity="error"
                variant="filled"
                style={{ backgroundColor: mainColors.important }}
                >
                    Please fill in the required fields
                </Alert>
            </Snackbar>  */}
        {   openSnackbar &&
            <div
            style={{
                position: "fixed",
                bottom: "3vh",
                left: "18vw"
            }}
            >
                <Alert severity="error"
                variant="filled"
                style={{ backgroundColor: mainColors.important }}
                >
                    Please fill in the required fields
                </Alert>
            </div>
        }
        {/* </Snackbar>  */}
        </>
    )
}

export default PageButtons