import { useEffect, useState } from "react"
import axios from "../api/axios"
import { MATCH_REVISIT_INFO } from "../pages/URLs"

const useRevisitMatching = (user, seenUser) => {
    const [res, setRes] = useState(null)

    useEffect(() => {
        const getMatchingData = async (user, seenUser) => {
            // TODO:LOD have to add function key for this
            await axios.post(MATCH_REVISIT_INFO, 
                JSON.stringify({ user, seenUser }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_REVISIT_INFO_KEY}` },
                    withCredentials: true
                }
            ).then(setRes)
        }
        getMatchingData(user, seenUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return res
}

export default useRevisitMatching