import { Link } from "react-router-dom"
import { landingPage } from "./URLs"
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const Missing = () => {
    return (
        <article style={{ padding: "100px" }}>
            <SEO
                title={seoText.missing.title}
                description={seoText.missing.description}
            />
            <h1>Oops!</h1>
            <p>Page Not Found</p>
            <div className="flexGrow">
                <Link to={landingPage}>Visit Our Homepage</Link>
            </div>
        </article>
    )
}

export default Missing
