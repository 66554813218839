import { Button, Typography } from "@mui/material"
import WestIcon from '@mui/icons-material/West';

const PrevButton = (props) => {

    const text = props.text
    const func = props.func

    return (
        <Button
        variant="backCP"
        onClick={ func }
        startIcon={ <WestIcon/> }
        sx={{px:"20px"}}
        style={{fontWeight: "lighter"}}>
            <Typography variant="body1">
            {text}
            </Typography>
        </Button>
    )
}

export default PrevButton