import { Grid, Typography } from "@mui/material";
import DecodedStructure from "../components/ProfileDecoder/DecodedStructureUpdate"
import { useNavigate } from "react-router-dom";
import { StructureProviderUpdate } from "../StructureDecoder/context/StructureProviderUpdate";
import { StructureProvider } from "../StructureDecoder/context/StructureProvider";
import MainBody from "../Layout/MainBody";
import RightContent from "../Layout/RightContent";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const pageOrder = [4, 3, 5, 2, 1]

const cardContent = [
    "Guru Bhakti Devi Dasi & Saranga Thakur Das (UK)",
    "We would like to thank Krishna Marriage for introducing us to each other. We feel blessed to raise a Krishna conscious family. We are just celebrating our 4th wedding anniversary and our wonderful daughter Palika will have her 3rd birthday soon."
]

const UpdateProfile = () => {

    const navigate = useNavigate()

    return (
        <>
            <SEO
                title={seoText.updateprofile.title}
                description={seoText.updateprofile.description}
            />
            <MainBody>
                <Grid item
                style={{height: "auto"}}
                sx={{px:"1.5vw", mb: "6vh"}}
                >
                    <StructureProvider>
                        <StructureProviderUpdate>
                            <DecodedStructure
                            pageOrder={pageOrder}
                            />
                        </StructureProviderUpdate>
                    </StructureProvider>

                </Grid>
            </MainBody>

            <RightContent>
                <Grid container
                style={{
                    width: "100%",
                    height: "100%",
                    wordWrap: "break-word",
                    overflow: "auto"
                }}
                sx={{
                    px: "1vw",
                    my: "1vh"
                }}
                >
                    <Grid container>
                    {
                        cardContent.map((text, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Typography variant="h6">
                                        {text}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                </Grid>
            </RightContent>
        </>
    )

}
    
export default UpdateProfile