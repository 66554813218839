import { Card, Grid } from "@mui/material"
import { useContext } from "react"
import LayoutContext from "./LayoutProvider"
import { motion } from "framer-motion"
import ResponsiveContext from "../ResponsiveDesign/ResponsiveProvider"

const RightContent = ({ children }) => {

    const { mainBodyWidth,
        navPicWidth,
        rightContentWidth,
        rightContentPaddingTop,
        rightContentInfoHeight,
        rightContentSpaceBetween,
        cardHeight,
        rightContentPaddingX,
        pageFadeDuration } = useContext(LayoutContext)

    const { isRemoveRightContent } = useContext(ResponsiveContext);

    return (
        <>
        {isRemoveRightContent && <Grid item
        style={{
            width: rightContentWidth,
            position: "fixed",
            zIndex: 2,
            left: `calc(${navPicWidth} + ${mainBodyWidth})`,
            top: `${rightContentPaddingTop+rightContentInfoHeight+rightContentSpaceBetween}vh`
        }}
        sx={{
            px: rightContentPaddingX
        }}
        >
            <Card
            style={{
                height: `${cardHeight}vh`
            }}
            elevation={0}
            >
                <motion.div
                style={{
                    height: "100%",
                    width: "100%"
                }}
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                transition={{
                    duration: pageFadeDuration/1000
                }}
                >
                    {children}
                </motion.div>
            </Card>
        </Grid>}
        </>
    )
}

export default RightContent