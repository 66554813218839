import { Grid, Typography } from "@mui/material"
import NonAuthLayout from "../Layout/NonAuthLayout"
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import deleteText from "../text/deleteProfile.json"

const DeleteProfileSuccessful = () => {
    return (
        <NonAuthLayout>
            <Grid container style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
            }}>
                <SEO
                    title={seoText.default.title}
                    description={seoText.default.description}
                />
                <Grid item style={{width: "max(50vw, 300px)"}}>
                    <Typography variant="h4" align="center">
                        {deleteText.successful}
                    </Typography>
                </Grid>
            </Grid>
        </NonAuthLayout>
    )
}

export default DeleteProfileSuccessful