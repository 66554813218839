import PageDecoder from "./PageDecoderUpdate";

const DecodedStructure = (props) => {

    const pageOrder = props.pageOrder
    return (
        <>
        {
            pageOrder.map(index => {
                const path = []
                path.push(index)
                return (
                    <PageDecoder
                    key={index}
                    path={path}/>
                    )
                }
            )
        }
        </>
    )
}

export default DecodedStructure