import { useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

const InfoToolTip = (props) => {

    const [toolTipOpen, setToolTipOpen] = useState(false);
    const handleClick = () => {setToolTipOpen(true)}

    return (
        <Tooltip 
        placement="right" 
        title={props.info}
        arrow
        open={toolTipOpen}
        onOpen={() => setToolTipOpen(true)}
        onClose={() => setToolTipOpen(false)}
        sx={{py:0}}
        >
            <IconButton onClick={handleClick}>
                <InfoIcon/>
            </IconButton>
        </Tooltip>
    )
}

export default InfoToolTip
