import PageDecoder from "./PageDecoder";

const DecodedStructure = (props) => {

    const index = props.index
    const path = []
    path.push(index+1)
    
    return (
        <PageDecoder path={path}/>
    )
}

export default DecodedStructure