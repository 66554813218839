import { Grid } from "@mui/material"
import { useState, useEffect, useContext } from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import StructureContext from "../../../StructureDecoder/context/StructureProvider";

const days = Array.from({length: 31}, (_, i) => i + 1)
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
const currYear = new Date().getFullYear()

var years = [];
for (var i = currYear-70; i <= currYear-18; i++) {
    years.push(i);
}

//TODO date validation and value of days

const CPDateComp = (props) => {

    const { page, setPage, required, setRequired } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])
    
    const date = thisPage[path[1]][path[2]].value.split("/")
    const error = required.includes(path[2])

    const handleFocus = () => {
        if (required.includes(path[2])) {
            const index = required.indexOf(path[2]);
            required.splice(index, 1);
            setRequired(required)
        }
    }

    return (
        <Grid container>

            <Grid item xs={3.63}>
                <FormControl fullWidth >
                        <Select
                            value={date[0] || ""}
                            onChange={(e) => {
                                thisPage[path[1]][path[2]].value = `${e.target.value}/${date[1] || ""}/${date[2] || ""}`
                                setPage(path[0], thisPage)
                            }}
                            size="small"
                            style={{shadow:"none"}}
                            sx={{mt:"17px", mx:1}}
                            error={error}
                            onFocus={handleFocus}
                        >
                            {days.map((discreteVal) => {
                                return <MenuItem key={discreteVal} value={discreteVal}
                                        style={{margin:0}}>
                                            {discreteVal}
                                        </MenuItem>
                            })}

                        </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4.13}>
            <FormControl fullWidth >
                    <InputLabel></InputLabel>
                        <Select
                            value={date[1] || ""}
                            onChange={(e) => {
                                thisPage[path[1]][path[2]].value = `${date[0] || ""}/${e.target.value}/${date[2] || ""}`
                                setPage(path[0], thisPage)
                            }}
                            size="small"
                            style={{shadow:"none"}}
                            sx={{mt:"17px", mx:1}}
                            error={error}
                            onFocus={handleFocus}
                        >
                            {months.map((discreteVal) => {
                                return <MenuItem key={discreteVal} value={discreteVal}
                                        style={{margin:0}}>
                                            {discreteVal}
                                        </MenuItem>
                            })}

                        </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4.24}>
            <FormControl fullWidth >
                    <InputLabel></InputLabel>
                        <Select
                            value={date[2] || ""}
                            onChange={(e) => {
                                thisPage[path[1]][path[2]].value = `${date[0] || ""}/${date[1] || ""}/${e.target.value}`
                                setPage(path[0], thisPage)
                            }}
                            size="small"
                            style={{shadow:"none"}}
                            sx={{mt:"17px", mx:1}}
                            error={error}
                            onFocus={handleFocus}
                        >
                            {years.map((discreteVal) => {
                                return <MenuItem key={discreteVal} value={discreteVal}
                                        style={{margin:0}}>
                                            {discreteVal}
                                        </MenuItem>
                            })}

                        </Select>
                </FormControl>
            </Grid>

        </Grid>
    )
}

export default CPDateComp