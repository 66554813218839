import { Button, Typography } from "@mui/material"

const Pill = (props) => {

    return (
        <Button
        color="textColor" 
        variant="outlined"
        >
            <Typography >

                {props.text}

            </Typography>
        </Button>
    )
}

export default Pill