import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAccessTokenInfo from "../hooks/useAccessTokenInfo";
import MainBody from "../Layout/MainBody";
import { homePage } from "./URLs";

const PaymentSuccess = () => {

    // const { qParams } = useParams()
    // const accessTokenEmail = useAccessTokenInfo("email")

    // useEffect(async () => {
        
    //     await axios.post(READY_FOR_MATCH, 
    //         JSON.stringify({ user: username }),
    //         {
    //             headers: { 'Content-Type': 'application/json' },
    //             withCredentials: true
    //         }
    //     )
    
    //   }, []);

    const navigate = useNavigate()
    useEffect(() => {
        navigate(homePage)
    }, [])

    return (
        <>
        </>
    )
}

export default PaymentSuccess