import { useState } from "react";
import { useParams } from "react-router-dom";
import MainBody from "../Layout/MainBody";
import useFullMatching from "../hooks/useFullMatching";
import LoadingComp from "../components/IndividualComponents/LoadingComp";
import { Grid, Typography } from "@mui/material";
import RightContent from "../Layout/RightContent";
import FullMatch from "../components/CombinedComponents/FullMatch";
import axios from "../api/axios";
import { UPDATE_MATCH_RESULT } from "./URLs";
import useAccessTokenInfo from "../hooks/useAccessTokenInfo";
import useRevisitMatching from "../hooks/useRevisitMatching";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const cardContent = [
    "Matching stats coming here soon…"
]

const RevisitProfile = () => {
    const { matchUserId, likeOrDislike } = useParams();
    const { user } = useAccessTokenInfo();
    const [res, setRes] = useState(null);
    const quickRes = useRevisitMatching(user, matchUserId);
    setTimeout(() => {
        setRes(quickRes)
    }, 1000) 

    if (!res) {
        return (
            <MainBody>
                <Grid container
                style={{
                    height: "100vh", width: "100%", display: "flex",
                    justifyContent: "center", alignItems: "center"
                }}>
                    <LoadingComp width="15vw" />
                </Grid>
            </MainBody>
        )
    }

    const acceptedFunc = async () => {
        if (likeOrDislike === "1") return;
        // TODO:LOD have to add function key for this
        await axios.post(UPDATE_MATCH_RESULT,
            JSON.stringify({ user, likeOrDislike: "1", seenUser: matchUserId }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_UPDATE_MATCH_RESULT}` },
                withCredentials: true
            }
        )
    };

    const rejectedFunc = async () => {
        if (likeOrDislike === "-1") return;
        await axios.post(UPDATE_MATCH_RESULT,
            JSON.stringify({ user, likeOrDislike: "-1", seenUser: matchUserId }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_UPDATE_MATCH_RESULT}` },
                withCredentials: true
            }
        )
    };

    return (
        <>
            <SEO
                title={seoText.default.title}
                description={seoText.default.description}
            />
            <MainBody>
                <Grid item
                style={{height: "auto"}}
                sx={{px:"1.5vw"}}
                display="flex"
                alignItems="start"
                >
                    <FullMatch matchingData={res.data}
                    acceptedOverride={acceptedFunc}
                    rejectedOverride={rejectedFunc}
                    />   
                </Grid>
            </MainBody>


            <RightContent>
                <Grid container
                style={{
                    width: "100%",
                    height: "100%",
                    wordWrap: "break-word",
                    overflow: "auto"
                }}
                sx={{
                    px: "1vw",
                    my: "1vh"
                }}
                >
                    <Grid container>
                    {
                        cardContent.map((text, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Typography variant="h6">
                                        {text}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                </Grid>
            </RightContent>
        </>
    )
}

export default RevisitProfile;