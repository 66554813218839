import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './auth/context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { ThemeProvider } from "@mui/material/styles"
import theme from './components/Theme/theme';
import { CssBaseline } from '@mui/material';
import AuthLayout from './pages/AuthLayout';
import { LayoutProvider } from './Layout/LayoutProvider';
import { ResponsiveProvider } from './ResponsiveDesign/ResponsiveProvider';
import { HelmetProvider } from 'react-helmet-async';

if (process.env.REACT_APP_NODE_ENV === 'production') {
disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <HelmetProvider>
    <ResponsiveProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <LayoutProvider>
            <AuthProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </AuthProvider>
          </LayoutProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ResponsiveProvider>
  </HelmetProvider>
);