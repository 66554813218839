import { Grid, IconButton, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { mainColors } from "../Theme/Colors/palette"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxPills from "./CheckBoxPills";
import FadeIn from "../../Animation/FadeIn";
import FadeInNormal from "../../Animation/FadeInNormal";

const arrayOfPrefs = [
    "family",
    "extrovert",
    "introvert",
    "shy",
    "outgoing",
    "community",
    "studious",
    "uplifting",
    "supportive",
    "honest",
    "practical",
    "sensitive",
    "motivated",
    "kind",
    "resilient",
    "serious",
    "logical",
    "open-minded",
    "assertive",
    "reliable",
    "responsible",
    "consistent",
    "appreciative",
    "patient",
    "thoughtful",
    "trustworthy",
    "funny",
    "punctual",
    "realistic",
    "empathetic",
    "brave",
    "calm",
    "optimistic",
    "forgiving",
    "enthusiastic",
    "ambitious",
    "respectful",
    "creative",
    "humble",
    "neat",
    "easy-going",
    "fashionable",
    "romantic",
    "charming",
    "relaxed",
    "adventurous",
    "frank",
    "clean",
    "gentle",
    "tolerant",
    "energetic",
    "generous",
    "self-controlled",
    "independent",
    "cultured",
    "curious",
    "decisive",
    "compassionate",
    "authentic",
    "encouraging",
    "considerate",
    "sincere",
    "balanced",
    "confident",
    "determined",
    "faithful",
    "liberal",
    "merciful",
    "reasonable"
]

const PreferencesOverlay = (props) => {

    const pixelOffset = 30
    const enterfunc = props.enterfunc

    const selectionRef = useRef(null)

    const [selectionShow, setSelectionShow] = useState(false);

    setTimeout(() => {
        setSelectionShow(true)
    }, 8000)

        return (
            <>
            <Grid container
            className="element"
            style={{background: "rgb(0,0,0)",
            overflowY: "auto"
            }}
            width="100vw"
            height="100vh"
            maxWidth={"100%"}
            >
                <Grid item xs={12}
                display="flex"
                alignItems="center"
                height="100vh"
                sx={{ mx: "7vw", mt: "5vh" }}
                >
                    <Grid container>

                        <Grid item
                        sx={{ mb:"2vh" }}
                        xs={12}
                        >
                            <Typography variant="h2" color={mainColors.background}>
                                Welcome to Matching...
                            </Typography>
                        </Grid>
                        <Grid item
                        sx={{ml:"10vw"}}
                        xs={12}
                        >
                            <FadeIn delay={1} >
                                <Typography variant="h3" 
                                color={mainColors.background}
                                sx={{ mt:`${pixelOffset}px` }}
                                >
                                    ... this is where you get to choose your dream partner
                                </Typography>
                            </FadeIn>
                        </Grid>
                        <Grid item
                        sx={{ml:"10vw"}}
                        xs={12}
                        >
                            <FadeIn delay={2} >
                                <Typography variant="h3" 
                                color={mainColors.background}
                                sx={{ mt:`${pixelOffset}px` }}
                                >
                                    ... this is where the magic happens
                                </Typography>
                            </FadeIn>
                        </Grid>

                        <Grid item
                        sx={{ mt:"6vh" }}
                        xs={12}
                        >
                            <FadeIn delay={4} >
                                <Typography variant="h2" color={mainColors.background}>
                                    First let's select your preferences...
                                </Typography>
                            </FadeIn>
                        </Grid>
                        <Grid item
                        sx={{ml:"10vw"}}
                        xs={12}
                        >
                            <FadeIn delay={5} >
                                <Typography variant="h3" 
                                color={mainColors.background}
                                sx={{ mt:`${pixelOffset}px` }}
                                >
                                    ... this helps Krishna Marriage to find the right partner for you
                                </Typography>
                            </FadeIn>
                        </Grid>
                        <Grid item
                        sx={{ml:"10vw"}}
                        xs={12}
                        >
                            <FadeIn delay={6} >
                                <Typography variant="h3" 
                                color={mainColors.background}
                                sx={{ mt:`${pixelOffset}px` }}
                                >
                                    ... choose up to 10 qualities you are seeking
                                </Typography>
                            </FadeIn>
                        </Grid>
                        <Grid item xs={12}
                        display="flex"
                        justifyContent="center"
                        sx={{ mt: "10vh" }}
                        >
                            <IconButton onClick={() => {
                                selectionRef.current.scrollIntoView({ behavior: "smooth" })
                            }}>
                                <FadeIn delay={7} duration={1}>
                                    <KeyboardArrowDownIcon style={{fill: mainColors.background,
                                    fontSize: "8vh"
                                    }}/>
                                </FadeIn>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>


                { selectionShow ?
                    <Grid item xs={12}
                    ref={selectionRef}
                    display="flex"
                    alignItems="center"
                    sx={{ mx: "18vw", my: "7vh" }}
                    minHeight="100vh"
                    >
                        <Grid container
                        >
                            <Grid item
                            xs={12}
                            >
                                <Typography variant="h2" color={mainColors.background}>
                                    Select your preferences
                                </Typography>
                            </Grid>
                            <Grid item
                            xs={12}
                            >
                                <CheckBoxPills arrayOfPrefs={arrayOfPrefs} enterfunc={enterfunc} max={10}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    : <></>
                }

            </Grid>
            </>
        )
}

export default PreferencesOverlay