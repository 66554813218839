import { Grid, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import axios from "../../api/axios"
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo"
import { GET_ABOUT_ME_URL, GET_PARTNER_PREF_URL, GET_PERSONAL_URL, GET_SPIRITUAL_URL } from "../../pages/URLs"
import StructureContext from "../../StructureDecoder/context/StructureProvider"
import SectionDecoder from "./SectionDecoder"
import { page_1, page_2, page_3, page_4 } from "./structure"

const apiArray = [GET_PERSONAL_URL, GET_ABOUT_ME_URL, GET_SPIRITUAL_URL, GET_PARTNER_PREF_URL]
const apiKeys = [process.env.REACT_APP_GET_PERSONAL_KEY, process.env.REACT_APP_GET_ABOUT_ME_KEY, process.env.REACT_APP_GET_SPIRITUAL_KEY, process.env.REACT_APP_GET_PARTNER_PREFS_KEY]

const PageDecoder = (props) => {

    const path = props.path

    const info = useAccessTokenInfo()
    const user = info?.email || ""


    const { page, setPage } = useContext(StructureContext)

    useEffect(() => {
        setPage("1", page_1)
        setPage("2", page_2)
        setPage("3", page_3)
        setPage("4", page_4)
    }, [])

    useEffect(() => {
        const thisPage = page(path[0])

        const updateValsStructure = (data) => {
            if (!data) {
                return
            }
            const keys = Object.keys(data)
            keys.forEach(key => {
                const fields = Object.keys(data[key])
                fields.forEach(field => {
                    if (key === "Personal" && field === "age") {
                        return
                    }
                    if (thisPage[key][field]) {
                        thisPage[key][field].value = data[key][field]
                    }
                })
            })
        }

        const getAboutMeData = async (username) => {
            if (username) {
                try {
                    await axios.post(apiArray[path[0]-1], 
                        JSON.stringify({ user: username }),
                        {
                            headers: { 'Content-Type': 'application/json',
                            'x-functions-key': `${apiKeys[path[0]-1]}` },
                            withCredentials: true
                        }
                    ).then(response => {
                        if (response?.data) {
                            updateValsStructure(response.data)
                            setPage(path[0], thisPage)
                        }
                    })
                } catch (err) {
                    if (err?.response?.data === "User not found") {
                        return
                    }
                    console.log(err)
                }
            }
        }
        getAboutMeData(user)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path[0]])

    const pageStructure = page(path[0])
    const titles = Object.keys(pageStructure)

    return (
        <Grid container
        sx={{mt:12}}>
            {titles.map((title) => {
                var pathPerTitle = [...path]
                pathPerTitle.push(title)
                return (
                    <Grid key={title} item xs={12}>
                        <Grid container>

                            <Grid item xs={12}
                            display="block"
                            justifyContent="left"
                            sx={{px:2}}>
                                <Typography variant="h3"
                                sx={{mr:"1.5vw", ml:2, mb:2}}>
                                    {title}
                                </Typography>
                                <Typography variant="body1"
                                sx={{mb: 2, mr: "1.5vw", ml: 2}}>
                                    ETC: {pageStructure[title].completionTime} mins
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <SectionDecoder 
                                path={pathPerTitle}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                )
            })}
        </Grid>
    )
}

export default PageDecoder