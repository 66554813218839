import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = (props) => {
  return (
    <Helmet prioritizeSeoTags>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title} />
      <meta property="og:url" content="https://krishnamarriage.com" />
      <meta property="og:image" content="https://krishnamarriage.com/static/media/desktopLanding-min.89b5a538361053582ac5.jpg" />
      <meta property="og:site_name" content="Krishna Marriage" />
      <meta property="og:description" content={props.description} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={props.title} />
      <meta property="twitter:description" content={props.description} />
      <meta property="twitter:image" content="https://krishnamarriage.com/static/media/desktopLanding-min.89b5a538361053582ac5.jpg" />
    </Helmet>
  )
}

export default SEO