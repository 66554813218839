import { Translate } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { CircularProgress, Grid } from "@mui/material"
import { motion } from "framer-motion"
import { mainColors } from "../Theme/Colors/palette"

const LoadingComp = ({ width, mainColor = mainColors.accent, secColor = mainColors.lightAccent }) => {

    return (
        <Grid container
        width={width}
        >
            <motion.svg
            width="100%"
            viewBox="0 0 187.8 92.8"
            >
                <motion.path
                fill="transparent"
                strokeWidth = "6"
                d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                stroke={secColor}
                pathLength={2}
                initial={{ strokeDashoffset: 0 }}
                animate={{ strokeDashoffset: 2 }}
                strokeLinecap="round"
                strokeDasharray="0.47 1.46 0.07 0"
                transition={{
                    duration: 2,
                    repeat:Infinity,
                    ease:"linear"
                }}
                style={{ scale: 2 }}
                />

                <motion.path
                fill="transparent"
                strokeWidth = "6"
                d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                stroke={mainColor}
                pathLength={2}
                initial={{ strokeDashoffset: 0 }}
                animate={{ strokeDashoffset: 2 }}
                strokeDasharray="0.4 1.6"
                strokeLinecap="round"
                transition={{
                    duration: 2,
                    repeat:Infinity,
                    ease:"linear"
                }}
                style={{ scale: 1.4 }}
                />
            </motion.svg>
        </Grid>
    )
}

export default LoadingComp