import { useEffect, useState } from "react"
import axios from "../api/axios"
import { READY_FOR_MATCH } from "../pages/URLs"

const useReadyForMatches = (user) => {

    const [res, setRes] = useState(null)

    useEffect(() => {

        const getReadyForMatches = async (username) => {
            await axios.post(READY_FOR_MATCH, 
                JSON.stringify({ user: username }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_CHECK_READY_FOR_MATCHES_KEY}` },
                    withCredentials: true
                }
            ).then(setRes)
        }
        getReadyForMatches(user)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return res
}

export default useReadyForMatches