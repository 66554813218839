import { Grid, Typography } from "@mui/material"

const InitialActionCenter = ({icon, text, func}) => {
  return (
    <div
    onClick={func}
    style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    }}
    >
    <Grid container
    style={{
        display: "flex",
        justifyContent: "center"
    }}
    sx={{px: "2vw", py: "2vh"}}
    >
        <Grid items
        sx={{
            mb: "2vh"
        }}
        >
            {icon}
        </Grid>
        <Grid item xs={12}>
            <Typography style={{fontSize: "3vh"}}
            align="center"
            >
                {text}
            </Typography>
        </Grid>
    </Grid>
    </div>
  )
}

export default InitialActionCenter