import { Grid, IconButton } from "@mui/material"
import { useContext } from "react"
import LayoutContext from "../../../Layout/LayoutProvider"
import NavBarContext from "../Context/NavBarProvider"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

const Base = ({ children }) => {

    const { navBarWidth } = useContext(LayoutContext)
    const { toggleNavBar, paddingTop, navToggleDisabled } = useContext(NavBarContext)

    return (
        <div style={{
            width: navBarWidth,
            position: "fixed",
            left: 0,
            display: "flex",
            justifyContent: "end"
        }}>
            <Grid container
            display="flex"
            flexDirection="column"
            alignItems="start"
            style={{height: "100vh"}}
            sx={{bgcolor: "rgba(255, 215, 111, 0.8)"}}>
                <Grid item
                sx={{mt:`${paddingTop}px`}}
                style={{ width: "100%" }}
                display="flex"
                justifyContent="center">
                        <IconButton onClick={toggleNavBar}
                        disabled={navToggleDisabled}>
                            <KeyboardDoubleArrowRightIcon
                            style={{height: "40px", width: "40px", fill: "black"}}/>
                        </IconButton>
                </Grid>

                {children}
            </Grid>
        </div>
    )
}

export default Base