import { Grid } from "@mui/material"
import MatchingTilesChip from "../IndividualComponents/MarketTilesChip"
import { mainColors } from "../Theme/Colors/palette"
import CheckIcon from '@mui/icons-material/Check'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import React from "react"
import { motion } from "framer-motion"

const bgColor = [
    mainColors.pink,
    mainColors.lightAccent,
    mainColors.lightAccent,
    mainColors.pink
]

const numOrIcon = [
    "3", 
    "5", 
    <CheckIcon/>, 
    <QuestionMarkIcon/>,
    "3",
    "7",
    "3",
    "5",
    <QuestionMarkIcon/>,
    <CheckIcon/>
]
const text = [
    "partner preferences matched",
    "matching services",
    "age range matched",
    "height range not matched",
    "common spiritual courses",
    "matching interests",
    "partner preferences matched",
    "matching services",
    "partner preferences matched",
    "read similar SP books"
]

const MatchingTiles = () => {
    return (
        <Grid container
        direction="column"
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
            px:"0.5vw",
            py:"1vh"
        }}
        >
            {numOrIcon.map((num, index) => {

                if (index % 2 === 0) {
                    return (
                        
                        <Grid item xs={2.4}
                        display="flex"
                        alignItems="center"
                        key={index}
                        >
                            <Grid container height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            >
                                <Grid item xs={6}
                                height="100%"
                                sx={{px:"0.4vw", py:"1vh"}}
                                >
                                    <motion.div
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ duration: 0.5, delay: (index+1)*0.2 }}
                                    viewport={{ once: true }}
                                    layout
                                    style={{height: "100%", width:"100%"}}
                                    >
                                        <MatchingTilesChip num={num} 
                                        text={text[index]} 
                                        color={bgColor[index % 4]}/>
                                    </motion.div>
                                </Grid>

                                <Grid item xs={6}
                                height="100%"
                                sx={{px:"0.4vw", py:"1vh"}}
                                >
                                    <motion.div
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ duration: 0.5, delay: (index+2)*0.2 }}
                                    viewport={{ once: true }}
                                    layout
                                    style={{height: "100%", width:"100%"}}
                                    >
                                        <MatchingTilesChip num={numOrIcon[index+1]} 
                                        text={text[index+1]} 
                                        color={bgColor[(index+1) % 4]}/>
                                    </motion.div>
                                </Grid>
                            </Grid>
                        </Grid>

                    )
                }

                return (<React.Fragment key={index}></React.Fragment>)
            })}
        </Grid>
    )
}

export default MatchingTiles