import { useMediaQuery } from "@mui/material";
import { createContext } from "react";

const ResponsiveContext = createContext({});

export const ResponsiveProvider = ({ children }) => {

    const isLaptopLanding = useMediaQuery('(min-width:1280px)');
    const isLaptop = isLaptopLanding;
    const isTablet = useMediaQuery('(min-width:600px)');
    const isTabletLanding = useMediaQuery('(min-width:940px)');
    const isRemoveRightContent = useMediaQuery('(min-width:1090px)')

    const resDesignLanding = (laptop, tablet, mobile) => {
        return isLaptopLanding ? `${laptop}px`
               : isTabletLanding ? `${tablet}px`
               : `${mobile}px`
    }

    const resDesign = (laptop, tablet, mobile) => {
        return isLaptop ? `${laptop}px`
               : isTablet ? `${tablet}px`
               : `${mobile}px`
    }

    return (
        <ResponsiveContext.Provider value={{ isLaptopLanding, isTabletLanding, resDesignLanding, isTablet, isLaptop, resDesign, isRemoveRightContent }}>
            {children}
        </ResponsiveContext.Provider>
    )
}

export default ResponsiveContext;