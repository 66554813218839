import { Box, Typography, CircularProgress } from "@mui/material";
// import StyledCircularProgress from "../IndividualComponents/StyledCircularProgress";
import { motion } from "framer-motion";

const CircularProgressWithLabel = (props) => {

    const size=props.size
    const thickness=props.thickness
    const value=props.value

    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>

        <CircularProgress 
        variant="determinate" 
        value={100}
        className="myBoxOnBottom" 
        size={size} 
        thickness={thickness}
        color="lightAccent"/>

        <CircularProgress 
        variant="determinate" 
        className="myBoxOnTop"
        size={size} 
        thickness={thickness}
        value={value}
        color="accent"
        style={{ animationDuration: "8s" }}
        />

        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5" style={{fontWeight: "bold"}} component="div">
            {`${props.value}%`}
          </Typography>
        </Box>
      </Box>
    );
}

export default CircularProgressWithLabel