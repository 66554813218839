import { Grid, Modal, Paper, Typography, useMediaQuery } from "@mui/material"
import { useNavigate } from "react-router-dom"
import SubscriptionsCard from "../components/CombinedComponents/SubscriptionsCard"
import SubTier1 from "../img/SubTier1-min.jpg"
import SubTier2 from "../img/SubTier2-min.jpg"
import SubTier3 from "../img/SubTier3-min.jpg"
import { paymentPage } from "./URLs"
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import useAccessTokenInfo from "../hooks/useAccessTokenInfo"
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const Subscriptions = () => {
    const info = useAccessTokenInfo();
    const isSubbed = info?.isSubbed;

    const navigate = useNavigate()
    const subCardArr = [
        {
            image: SubTier1,
            title: "Casually Looking",
            text: [ "Let the algorithm do the work!", "Profiles with 3 days cooldown", "Candidate profiles are subject to availability" ],
            buttonText: process.env.REACT_APP_SUBSCRIPTION_ENABLED === "true" ? isSubbed ? "Purchased" : "Purchase" : "Coming Soon",
            color: "accent",
            disabled: process.env.REACT_APP_SUBSCRIPTION_ENABLED === "false",
            func: isSubbed ? () => null : () => navigate(paymentPage)
        },
        {
            image: SubTier2,
            title: "Serious Endeavour",
            text: [ "Search our database yourself!", "Profiles with no cooldown", "Candidate profiles are subject to availability" ],
            buttonText: "Purchase",
            color: "accent",
            disabled: true,
            func: () => null
        },
        {
            image: SubTier3,
            title: "Matchmake Me",
            text: [ "Work with a match maker!", "Personalised searches with no restriction", "Candidate profiles are subject to availability" ],
            buttonText: "Purchase",
            color: "important",
            disabled: true,
            func: () => null
        },
    ]

    const isDesktopSub = useMediaQuery('(min-width:1100px)')

    return (
        <>
        <SEO
            title={seoText.subscriptions.title}
            description={seoText.subscriptions.description}
        />

        <Modal
        open={true}
        style={{ overflow: "scroll" }}
        className="no-scroll"
        >
            
            <Grid container
            sx={{px:"10vw", pt:"3vh", height: "100vh", pb:"5vh"}}
            style={{ backgroundColor: "#000" }}
            display="flex"
            justifyContent={"center"}
            >

                <Typography variant="h2"
                sx={{px:"1vw"}}
                color="#fff"
                fontFamily={"Quicksand"}
                fontWeight={3}
                textAlign={"center"}
                width={"100%"}
                >
                    {"Subscriptions".toUpperCase()}
                </Typography>

                { isDesktopSub ? (
                    <Grid container
                    sx={{ width: "100vw", maxWidth: "100%"}}
                    display="flex"
                    alignItems="center"
                    height="max(80vh, 500px)"
                    >
                    {subCardArr.map(({
                        image,
                        title,
                        text,
                        buttonText,
                        color,
                        func,
                        disabled
                    }) => {
                        return (
                                <Grid item xs={4}
                                sx={{px:"1vw", py:"1vh"}}
                                height="100%"
                                >
                                    <SubscriptionsCard
                                    image={image}
                                    title={title}
                                    text={text}
                                    buttonText={buttonText}
                                    color={color}
                                    func={func}
                                    disabled={disabled}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    ) : (
                    <Grid container
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="max(80vh, 500px)"
                    width={"max(60%, 300px)"}>
                        <Swiper modules={[Pagination]}
                        pagination={{ clickable: true }}
                        style={{ width: "100%" }}
                        spaceBetween={50}>
                            {subCardArr.map(({
                                image,
                                title,
                                text,
                                buttonText,
                                color,
                                func,
                                disabled
                            }) => {
                                return (
                                    <SwiperSlide style={{ width: "45%" }} key={title}>
                                        <Grid container
                                        display="flex"
                                        alignItems="center"
                                        height="max(80vh, 500px)"
                                        width={"100%"}>
                                            <SubscriptionsCard
                                            image={image}
                                            title={title}
                                            text={text}
                                            buttonText={buttonText}
                                            color={color}
                                            func={func}
                                            disabled={disabled}
                                            />
                                        </Grid>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </Grid>
                )}
            </Grid>
        </Modal>
        </>
    )
}

export default Subscriptions