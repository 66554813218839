import { Box, Card, CardMedia, Grid, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { mainColors } from "../Theme/Colors/palette";
import LoadingComp from "./LoadingComp";
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo";
import { DELETE_PICS_URL } from "../../pages/URLs";
import axios from "../../api/axios";
import { useContext, useEffect } from "react";
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider";

const ShowImage = ({ images, setImages, setErrMsg, indexId, setIndexId, loading }) => {
  const email = useAccessTokenInfo("email")
  const { isTablet, isLaptop } = useContext(ResponsiveContext)

  return (
    <Grid container
    display="flex"
    spacing={2}
    >
      {images.map((image, index) => (
        <Grid item key={index}> 
          <Card
          style={{ border: "0px solid" }}
          >
            <div style={{position: "relative"}}>
              <CardMedia
              style={{ position: "relative", width: isLaptop ? "300px" : isTablet ? "250px" : "86vw" }}
              component="img"
              src={image.src}
              />
              {
                loading.includes(image.id) && <div style={{ position: "absolute", top: 0, left: 0, height: "100%" }}>
                  <Grid container
                  style={{
                    height: "100%",
                    width: isLaptop ? "300px" : isTablet ? "250px" : "86vw",
                    background: "rgba(255, 215, 111, 0.5)"
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  >
                      <LoadingComp width="50%" />
                  </Grid>
                </div>
              }
              <div
              style={{ position: "absolute",
              top: "4%",
              right: "4%",
              transform: "translateX(-4%) translateY(-4%)"
              }}
              >
                {!loading.includes(image.id) && <IconButton
                style={{ background: mainColors.primary, padding:"0.5vh" }}
                onClick={() => {
                  indexId.push(image.id)
                  setIndexId([...indexId])
                  images.splice(index, 1)
                  setImages([...images])
                  setErrMsg("")
                  axios.post(DELETE_PICS_URL, 
                    JSON.stringify({ email, picKey: `${image.id}` }),
                    {
                        headers: { 'Content-Type': 'application/json',
                        'x-functions-key': `${process.env.REACT_APP_DELETE_PICS_KEY}` },
                        withCredentials: true
                    }
                  )
                }}
                >
                <CloseIcon style={{ fill: mainColors.accent, fontSize: "2.5vh" }}
                stroke={mainColors.accent}
                strokeWidth={1}
                />
                </IconButton>}
              </div>
            </div>
          </Card>
        </Grid>
      ))} 
    </Grid>
    
  )
}

export default ShowImage