import { useNavigate } from "react-router-dom"
import { landingPage } from "./URLs";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(landingPage);

    return (
        <section>
            <SEO
                title={seoText.unauthorized.title}
                description={seoText.unauthorized.description}
            />
            <h1>Unauthorized</h1>
            <br />
            <p>You do not have access to the requested page.</p>
            <div className="flexGrow">
                <button onClick={goBack}>Go Back</button>
            </div>
        </section>
    )
}

export default Unauthorized
