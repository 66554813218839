import { Grid, Link, Typography } from "@mui/material"
import MainBody from "../Layout/MainBody"
import { useContext } from "react"
import LayoutContext from "../Layout/LayoutProvider"
import AuthContext from "../auth/context/AuthProvider"
import NonAuthLayout from "../Layout/NonAuthLayout"
import { useNavigate } from "react-router-dom"
import { dataProtectionPolicy, faqPage, guidelinesPage, privacyPolicy, termsAndConditionsPage } from "./URLs"
import { mainColors } from "../components/Theme/Colors/palette"
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const linkStyle = {
    cursor: "pointer"
}

const UsefulLinks = () => {
    const navigate = useNavigate()

    return (
        <NonAuthLayout>
            <SEO
                title={seoText.usefullinks.title}
                description={seoText.usefullinks.description}
            />
            <Grid container style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
            sx={{ px: "7vw" }}>
                <Grid container style={{ width: "max(300px, 50vw)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Grid item xs={12}>
                        <Typography variant="h3">
                            Useful Links
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ mt: "20px" }}>
                                        <Typography variant="h4">
                                            Legal
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" onClick={() => navigate(termsAndConditionsPage)} style={linkStyle}>
                                            Terms
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" onClick={() => navigate(guidelinesPage)} style={linkStyle}>
                                            Guidelines
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" onClick={() => navigate(privacyPolicy)} style={linkStyle}>
                                            Privacy
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" onClick={() => navigate(dataProtectionPolicy)} style={linkStyle}>
                                            Data Protection
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Refund policy
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: "20px" }}>
                                        <Typography variant="h4">
                                            Other
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" onClick={() => navigate(faqPage)} style={linkStyle}>
                                            FAQs
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ mt: "20px" }}>
                                        <Typography variant="h4">
                                            Get in touch
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Admin requests
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Bug report
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Report a profile
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: "20px" }}>
                                        <Typography variant="h4">
                                            Socials
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={linkStyle}>
                                        <Typography variant="h6">
                                            <Link href="https://www.instagram.com/krishna_marriage/?hl=en-gb"
                                            color={mainColors.textColor}>Instagram</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={linkStyle}>
                                            <Link href="https://www.facebook.com/krishnamarriage/"
                                            color={mainColors.textColor}>Facebook</Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </NonAuthLayout>
    )
}

export default UsefulLinks