import NonAuthLayout from "../Layout/NonAuthLayout";
import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import privacy from "../text/privacy.json"

for(let i=0; i<privacy.policy.length; i++ ) {
    if(privacy.policy[i].text instanceof Array) {
        let joinedText = privacy.policy[i].text.join('\n\n')
        privacy.policy[i].text = joinedText
    }
}

const PrivacyPolicy = () => {
    return (
        <NonAuthLayout>
            <SEO
                title={seoText.privacypolicy.title}
                description={seoText.privacypolicy.description}
            />
            <TitleTextPageLayout titleTextArray={privacy.policy} headerTextObj={privacy.intro} footerTextObj={privacy.footer}/>
        </NonAuthLayout>
    )
}

export default PrivacyPolicy
