import { Favorite, Settings } from "@mui/icons-material"
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { Grid, Typography } from "@mui/material"
import MainBody from "../Layout/MainBody"

const PaymentFailed = () => {

    const size = "10vh"

    return (
        <>
        <MainBody>
            <Typography
            variant="h3"
            sx={{ my:"2vh", mx: "1vw" }}
            >
                Payment Failed
            </Typography>
        </MainBody>
        </>
    )
}

export default PaymentFailed