import { useEffect, useState } from "react"
import axios from "../api/axios"
import { GET_USER_MATCH_HISTORY } from "../pages/URLs"

const useGetUserMatchHistory = (user) => {

    const [res, setRes] = useState(null)

    useEffect(() => {

        const getUserMatchData = async (username) => {
            if (!username) {
                return
            }
            try {
                await axios.post(GET_USER_MATCH_HISTORY, 
                    JSON.stringify({ email: username }),
                    {
                        headers: { 'Content-Type': 'application/json',
                        'x-functions-key': `${process.env.REACT_APP_GET_USER_MATCH_HISTORY}` },
                        withCredentials: true
                    }
                ).then(setRes)
            } catch (err) {
                if (err?.response.data === "User not found") {
                    setRes("Not found")
                }
            }
        }
        getUserMatchData(user)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return res
}

export default useGetUserMatchHistory