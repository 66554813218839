import { Button, Drawer, Grid, Typography } from "@mui/material"
import { useContext } from "react"
import NavBarContext from "../Context/NavBarProvider"
import LayoutContext from "../../../Layout/LayoutProvider"
import { motion } from "framer-motion"
import { mainColors } from "../../Theme/Colors/palette"

const FullView = () => {
    const { paddingLeft, paddingTop, navBarPages, open, navButtonDisabled, navBarDuration, heightOfAvatar, switchMenu, toggleNavBar } = useContext(NavBarContext)
    const { navPicWidth, originalNavPicWidth } = useContext(LayoutContext)

    return (
        <Drawer open={switchMenu}
        onClose={toggleNavBar}
        anchor={"bottom"}
        PaperProps={{
            sx: {
                position: "fixed",
                backgroundColor: mainColors.primary,
                lineHeight: "10px",
                minHeight: "10px",
                fontSize: "10px",
                pb: "25px",
                pt: "10px"
            }
        }}>
            { navBarPages.map((props, index) => {
                if (index % 2 === 1) return (<></>)
                return (
                    <motion.div
                    style={{
                        width: "100vw",
                        marginTop: (index === 0) ? "20px" : "0px",
                        display: "flex"
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: open ? 1 : 0 }}
                    transition={{
                        duration: navBarDuration/5000,
                        delay: open ? 0.2 + index*0.04 : 0
                    }}>
                        <Grid item
                        style={{
                            width: "50vw",
                            paddingLeft: "5vw"
                        }}>
                            <Button disableRipple
                            variant="backCP"
                            startIcon={props.icon}
                            style={{ background: "transparent" }}
                            sx={{ "&.Mui-disabled": { color: "black" }}}
                            onClick={props.func}
                            disabled={navButtonDisabled || props?.disabled}>
                                <Typography variant="h6"
                                color={props?.disabled ? "#5e5e5e" : "black"}
                                >
                                    {props.label}
                                </Typography>
                            </Button>
                        </Grid>
                        
                        { index + 1 < navBarPages.length && <Grid item
                        style={{
                            width: "50vw",
                            paddingLeft: "5vw"
                        }}>
                            <Button disableRipple
                            variant="backCP"
                            startIcon={navBarPages[index + 1].icon}
                            style={{ background: "transparent" }}
                            sx={{ "&.Mui-disabled": { color: "black" }}}
                            onClick={navBarPages[index + 1].func}
                            disabled={navButtonDisabled || navBarPages[index + 1]?.disabled}>
                                <Typography variant="h6"
                                color={navBarPages[index + 1]?.disabled ? "#5e5e5e" : "black"}
                                >
                                    {navBarPages[index + 1].label}
                                </Typography>
                            </Button>
                        </Grid>}
                    </motion.div>
                )
            })}
        </Drawer>
    )
}

export default FullView