import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';
import useLocalStorage from "../hooks/useLocalStorage";
import errLogger from "../logger/errLogger";
import LoadingComp from "../components/IndividualComponents/LoadingComp";
import { Grid } from "@mui/material";
import MainBody from "../Layout/MainBody";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const [persist] = useLocalStorage('persist', false);

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                console.log(err.response)
            }
            finally {
                isMounted && setTimeout(() => {setIsLoading(false)}, 2000) ;
            }
        }

        // Avoids unwanted call to verifyRefreshToken
        !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, [auth?.accessToken, persist, refresh])

    return (
        <>
            {!persist
                ? <Outlet />
                : isLoading
                    ? (
                        <MainBody>
                            <Grid container
                            style={{
                                height: "100vh",
                                width: "100%",
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            >
                                <LoadingComp width="15vw" />
                            </Grid>
                        </MainBody>
                    )
                    : <Outlet />
            }
        </>
    )
}

export default PersistLogin