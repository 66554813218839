import { Avatar, Grid } from "@mui/material"
import { useState, useCallback, useEffect } from "react"
import DropBox from "../IndividualComponents/DropBox"
import imageCompression from "browser-image-compression"
import axios from "../../api/axios"
import { DELETE_PICS_URL, GET_PICS_URL, UPLOAD_PICS_URL } from "../../pages/URLs"
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo"
import KMButton from "../IndividualComponents/KMButton"
import LoadingComp from "../IndividualComponents/LoadingComp"

const ProfilePicDragDropComp = ({ images, setImages }) => {

    const accessTokenEmail = useAccessTokenInfo("email")

    useEffect(() => {
        setImages([])
        axios.post(GET_PICS_URL, 
            JSON.stringify({
                email: accessTokenEmail,
                picKey: "profilePic"
            }),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${process.env.REACT_APP_GET_PICS_KEY}` },
                withCredentials: true
            }
            ).then(res => {
                if (res.data === "None") {
                    return
                }
                setImages((prevState) => [
                    ...prevState,
                    { id: "profilePic", src: res.data.src },
                ])
                setAvatarOpen(true)
            })
    }, [])

    const [errMsg, setErrMsg] = useState("");
    const [avatarOpen, setAvatarOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            setErrMsg("You can only upload 1 profile picture")
            return
        }
        acceptedFiles.map((file) => {
            if (file.size > 50000000) {
                return
            }
            const imageFile = file
            const options = {
                maxSizeMB: 0.05,
                maxWidthOrHeight: 700,
                useWebWorker: true
            }
            imageCompression(imageFile, options).then(compressedFile => {
                var reader = new FileReader()
                reader.readAsDataURL(compressedFile)
                reader.onloadend = () => {
                    setLoading(true)
                    try {
                        axios.post(UPLOAD_PICS_URL, 
                            JSON.stringify({
                                email: accessTokenEmail,
                                pic: { src: reader.result, id: "profilePic" }
                            }),
                            {
                                headers: { 'Content-Type': 'application/json',
                                'x-functions-key': `${process.env.REACT_APP_UPLOAD_PICS_KEY}` },
                                withCredentials: true
                            }
                            ).then(() => {
                                setLoading(false)
                            })
                    } catch (err) {
                        console.log(err)
                        setLoading(false)
                        return
                        // TODO continue
                    }
                    setImages((prevState) => [
                        ...prevState,
                        { id: "profilePic", src: reader.result },
                    ])
                    setAvatarOpen(true)
                }
            })
        })
    })

    return (
        <div
        style={{padding: "1.5vh"}}
        >
            {avatarOpen && images.length !== 0
            ? (
                <Grid container
                direction="column"
                display="flex"
                alignItems="center"
                >
                    <Grid item
                    sx={{mb:"2vh"}}
                    >
                        <Grid container position="relative">
                            <Avatar src={images[0].src} alt="Your pic"
                            style={{ height: "15vh", width: "15vh" }}
                            />
                            { loading && <Grid container
                            style={{
                                height: "15vh",
                                width: "15vh",
                                background: "rgba(255, 215, 111, 0.5)",
                                borderRadius: "50%"
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            position="absolute"
                            >
                                <LoadingComp width="50%" />
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <KMButton text={"Select another picture"}
                        onClick={() => {
                            setImages([])
                            setAvatarOpen(false)
                        }}/>
                        <KMButton text={"Delete picture"}
                        onClick={() => {
                            axios.post(DELETE_PICS_URL, 
                                JSON.stringify({ email: accessTokenEmail, picKey: `profilePic` }),
                                {
                                    headers: { 'Content-Type': 'application/json',
                                    'x-functions-key': `${process.env.REACT_APP_DELETE_PICS_KEY}` },
                                    withCredentials: true
                                }
                            )
                            setImages([])
                            setAvatarOpen(false)
                        }}/>
                    </Grid>
                </Grid>
            )
            : <DropBox onDrop={onDrop} errMsg={errMsg}/>}
        </div>
    )
}

export default ProfilePicDragDropComp