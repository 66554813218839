import { Box, Button, Card, Grid, Typography } from "@mui/material"
import { mainColors } from "../Theme/Colors/palette"
import { motion } from "framer-motion"

const SubscriptionsCard = ({
    image,
    title,
    text,
    buttonText,
    color,
    func,
    disabled
}) => {
    return (
        <motion.div
        initial={{ borderRadius: 20 }}
        whileHover={{ scale: 1.02, boxShadow: `0px 0px 60px ${mainColors[color]}` }}
        style={{ height: "100%", width:"100%", borderRadius: 20 }}>
            <Card
            sx={{ height: "100%", px:"7%" }}
            style={{
                borderColor: mainColors[color], 
                borderWidth: "0px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center"
            }}>
                <Box component="img"
                sx={{ height: "45%" }}
                src={image}
                style={{ margin: 0 }}/>
                <div style={{ height: "30%", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                    <Typography variant="h3"
                    align="center"
                    width="100%">
                        {title}
                    </Typography>
                    <div style={{ width: "100%" }}>
                        <Typography variant="h6"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        align="center"
                        width="100%">
                            {text[0]}
                        </Typography>
                        <Typography variant="h6"
                        align="center"
                        width="100%">
                            {text[1]}
                        </Typography>
                        <Typography variant="body1"
                        align="center"
                        width="100%">
                            {text[2]}
                        </Typography>
                    </div>
                </div>
                <Button disableElevation
                variant="contained"
                color={color}
                disabled={disabled}
                sx={{px:"2vw", mb:"4%", height:"9%", mt: "8%"}}
                onClick={func}>
                    <Typography style={{ fontSize: "20px" }}
                    fontFamily="Quicksand">
                        {disabled ? "Coming soon".toUpperCase() : buttonText.toUpperCase()}
                    </Typography>
                </Button>
            </Card>
        </motion.div>
    )
}

export default SubscriptionsCard