import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box, Typography } from '@mui/material';

const TextOverIcon = () => {

    return (
        <>
        <Box height="25vh" width="25vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        >

        <FavoriteIcon className='myBoxOnBottom'
        style={{
            fontSize: "25vh",
            // fill: "#f5754e",
            fill: "grey"
        }}
        />
        <Typography className='myBoxOnTop'
        color="white"
        fontSize="4.5vh"
        align="center"
        >
            {/* matching score */}
            Coming Soon
        </Typography>

        </Box>

        </>
    )

}

export default TextOverIcon