import NonAuthLayout from "../Layout/NonAuthLayout";
import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import contact from "../text/contact.json"

for(let i=0; i<contact.policy.length; i++ ) {
    if(contact.policy[i].text instanceof Array) {
        let joinedText = contact.policy[i].text.join('\n\n')
        contact.policy[i].text = joinedText
    }
}

const ContactUs = () => {
    return (
        <NonAuthLayout>
            <SEO
                title={seoText.contactus.title}
                description={seoText.contactus.description}
            />
            <TitleTextPageLayout titleTextArray={contact.policy} headerTextObj={contact.intro} footerTextObj={contact.footer}/>
        </NonAuthLayout>
    )
}

export default ContactUs
