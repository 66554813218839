import { Grid, Typography } from "@mui/material"
import availableComp from "./availableComp"
import InfoToolTip from "./CompleteProfileIndividualComps/InfoToolTip";
import React from "react";
import DragAndDropComp from "../CombinedComponents/DragAndDropComp";
import ProfilePicDragDropComp from "../CombinedComponents/ProfilePicDragDropComp";
import { useContext } from "react";
import StructureContext from "../../StructureDecoder/context/StructureProvider";
import { red } from "@mui/material/colors";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider";

const SectionDecoder = (props) => {

    const { isTablet } = useContext(ResponsiveContext)
    const { page, required } = useContext(StructureContext)
    const path = props.path
    const sectionStructure = page(path[0])[path[1]]
    const fields = Object.keys(sectionStructure)

    const fieldsFunction = (field, index) => {

        var fieldPath = [...path]
        fieldPath.push(field)

        const fieldProps = sectionStructure[field]
        var isWildCard = false

        var show = true
        if (fieldProps?.dependsOn) {
            if (fieldProps.dependsOn.value[0] === "*") {
                isWildCard = true
                if (sectionStructure[fieldProps.dependsOn.field].value !== "") {
                    isWildCard = false
                }
            } else if (!fieldProps.dependsOn.value.includes(sectionStructure[fieldProps.dependsOn.field].value)) {
                show=false
            }
        }

        
        //default vals
        var compProp = fieldProps?.component || "text"
        if (isWildCard) {
            compProp = "empty"
        }
        var labelProp = ""
        if (field === "empty") {
            labelProp = ""
        } else if (isWildCard) {
            labelProp = ""
        } else {
            labelProp = fieldProps?.label || field
        }
        const fillProp = fieldProps?.fill || "full"


        let xsVal = (fillProp === "full")? 12: 6
        if (!isTablet) {
            xsVal = 12
        }
        const requiredComp = availableComp[compProp]
        const infoMark = (fieldProps?.info)
                        ? (<InfoToolTip info={fieldProps.info} />)
                        : (<></>)
        const requiredMark = required.includes(field)
                            ? (<Typography
                                color={"important"}
                                sx={{
                                    ml: "0.5vw",
                                    fontSize: "2vh"
                                }}
                            >
                                *
                            </Typography>)
                            : (<Typography
                                color={"secondary"}
                                sx={{
                                    ml: "0.5vw",
                                    fontSize: "2vh"
                                }}
                            >
                                *
                            </Typography>)

        
        return (
            <React.Fragment key={index+field}>
                {show && <Grid item xs={xsVal} key={index + field} sx={{mb:2.5}}>
                    <Grid container direction="column">
                    
                        <Grid item xs={6} sx={{px:2}}>
                            <Typography 
                            variant="h5" 
                            sx={{ml:2}}
                            display="flex"
                            alignItems="center"
                            color={required.includes(field) ? red[700] : ""}
                            >
                                {labelProp.slice(-1) === "*" ? labelProp.slice(0,-1) : labelProp}
                                {labelProp.slice(-1) === "*" && requiredMark}
                                {infoMark}
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sx={{px:2}}
                        >
                                {requiredComp(fieldPath)}
                        </Grid>

                    </Grid>
                </Grid>}
            </React.Fragment>
        )
    }
    
    return (
        <Grid container direciton="row">

            {fields.map((field, index) => {
                if (field === "completionTime") {
                    return
                }
                return fieldsFunction(field, index)
            })}
        </Grid>
    )
}

export default SectionDecoder