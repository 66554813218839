import { useEffect, useState } from "react"
import axios from "../api/axios"
import { TEST_STRIPE_CHECKOUT } from "../pages/URLs"
import useAccessTokenInfo from "./useAccessTokenInfo"

const useStripeKey = () => {

    const user = useAccessTokenInfo("email")
    const [res, setRes] = useState(null)

    useEffect(() => {

        const getStripeKey = async () => {
            await axios.post(TEST_STRIPE_CHECKOUT, 
                JSON.stringify({"user": user, "url": process.env.REACT_APP_MY_URL}),
                {
                    headers: { 'Content-Type': 'application/json',
                    "x-functions-key": `${process.env.REACT_APP_CHECKOUT_SESSION_URL}`
                },
                    withCredentials: true,
                }).then(setRes)
        }
        getStripeKey()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return res
}

export default useStripeKey