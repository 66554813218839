import { Box, Card, CardMedia, Grid, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { mainColors } from "../Theme/Colors/palette";
import { useContext, useEffect } from "react";
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider";

const ShowImageMatch = ({ images, setImages, setErrMsg, cleanUpIds, setCleanUpIds, indexId, setIndexId }) => {
  const { isTablet, isLaptop } = useContext(ResponsiveContext)

  return (
    <Grid container
    display="flex"
    spacing={2}
    >
      {images.map((image, index) => (
        <Grid item key={index}> 
          <Card
          style={{ border: "0px solid" }}
          >
            <div style={{position: "relative", height: "100%"}}>
              <CardMedia
              style={{ width: isLaptop ? "300px" : isTablet ? "250px" : "86vw" }}
              component="img"
              src={image.src}
              />
              <div
              style={{ position: "absolute",
              top: "4%",
              right: "4%",
              transform: "translateX(-4%) translateY(-4%)"
              }}
              >
                {/* <IconButton
                style={{ background: mainColors.primary, padding:"0.5vh" }}
                onClick={() => {
                  cleanUpIds.push(image.id)
                  setCleanUpIds([...cleanUpIds])
                  indexId.push(image.id)
                  setIndexId([...indexId])
                  images.splice(index, 1)
                  setImages([...images])
                  setErrMsg("")
                }}
                >
                <CloseIcon style={{ fill: mainColors.accent, fontSize: "2.5vh" }}
                stroke={mainColors.accent}
                strokeWidth={1}
                />
                </IconButton> */}
              </div>
            </div>
          </Card>
        </Grid>
      ))} 
    </Grid>
    
  )
}

export default ShowImageMatch