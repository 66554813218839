import { Autocomplete, TextField } from "@mui/material"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import StructureContext from "../../../StructureDecoder/context/StructureProvider";

const industriesList = `Accountancy, banking and finance
Business, consulting and management
Charity and voluntary work
Creative arts and design
Energy and utilities
Engineering and manufacturing
Environment and agriculture
Healthcare
Hospitality and events management
Information technology
Law
Law enforcement and security
Leisure, sport and tourism
Marketing, advertising and PR
Media and internet
Property and construction
Public services and administration
Recruitment and HR
Retail
Sales
Science and pharmaceuticals
Social care
Teacher training and education
Transport and logistics
`

const actualList = industriesList.split("\n");

const CPAutocompleteIndustry = (props) => {
    const [countries, setCountries] = useState(actualList);
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);

    const { page, setPage, required, setRequired } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])

    const handleFocus = () => {
        setFocused(true)
        setOpen(true)
        if (required.includes(path[2])) {
            const index = required.indexOf(path[2]);
            required.splice(index, 1);
            setRequired(required)
        }
    }

    return (
        <Autocomplete
        fullWidth
        options={countries}
        value={thisPage[path[1]][path[2]].value}
        onChange={(e, newval) => {
            thisPage[path[1]][path[2]].value = newval
            setPage(path[0], thisPage)
        }}
        onFocus={handleFocus}
        open={open}
        onOpen={() => {
            if (focused) {
                setOpen(true)
            }
        }}
        onClose={() => {
            setOpen(false)
        }}
        onBlur={() => {
            setFocused(false)
        }}
        renderInput={(params) => <TextField
            {...params}
            sx={{mt:2, px:1}}
            margin="dense"
            size="small"
            value={thisPage[path[1]][path[2]].value}
            />
        }
        />
    )
}

export default CPAutocompleteIndustry