import { Card, Grid, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useState, useContext } from "react";
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider";

const ActionCenterCard = ({ text, icon, dismiss, variable, func}) => {
    const keyVariable = variable
    const [hover, setHover] = useState(false);
    const { isRemoveRightContent } = useContext(ResponsiveContext);

    return (
        <Card
        style={{border: "0px solid",
        width: "100%",
        borderRadius: 5,
        }}
        elevation={hover ? 2 : 0}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        sx={{my:"0.5vh"}}
        >
            <Grid container
            display="flex"
            alignItems="center"
            >
                <Grid item
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={func}
                >
                    {icon}
                </Grid>
                <Grid item xs={true}
                sx={{py:"0.5vh"}}
                onClick={func}
                >
                    <Typography variant="h6"
                    style={{textOverflow: "ellipsis"}}
                    >
                        {text}
                    </Typography>
                </Grid>
                {!isRemoveRightContent || hover
                ? (
                    <Grid item>
                        <IconButton onClick={() => dismiss(keyVariable)}>
                            <CloseIcon style={{ fontSize: "2vh",
                            fill: "red" }}/>
                        </IconButton>
                    </Grid>
                )
                : <></>
                }

            </Grid>
        </Card>
    )
}

export default ActionCenterCard