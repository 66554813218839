import NonAuthLayout from "../Layout/NonAuthLayout";
import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import terms from "../text/terms.json"

for(let i=0; i<terms.policy.length; i++ ) {
    if(terms.policy[i].text instanceof Array) {
        let joinedText = terms.policy[i].text.join('\n\n')
        terms.policy[i].text = joinedText
    }
}

const TermsAndConditions = () => {
    return (
        <NonAuthLayout>
            <SEO
                title={seoText.termsandconditions.title}
                description={seoText.termsandconditions.description}
            />
            <TitleTextPageLayout titleTextArray={terms.policy} headerTextObj={terms.intro} footerTextObj={terms.footer}/>
        </NonAuthLayout>
    )
}

export default TermsAndConditions
