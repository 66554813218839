import { Avatar, Grid, Typography } from "@mui/material"
import { mainColors } from "../Theme/Colors/palette"
import desktopFooter from "../../img/desktopFooter-min.jpg"
import { useContext } from "react"
import ResponsiveContext from "../../ResponsiveDesign/ResponsiveProvider"
import { useNavigate } from "react-router-dom"
import { contactUsPage, faqPage, privacyPolicy, termsAndConditionsPage } from "../../pages/URLs"

const linkStyle = {
    cursor: "pointer"
}

const Footer = () => {

    const { isTabletLanding } = useContext(ResponsiveContext);

    const navigate = useNavigate()

    return (
        <Grid container
        style={{background: mainColors.primary}}
        direction="row"
        justifyContent={ isTabletLanding ? "space-between" : "center" }
        alignItems="center"
        >
            <Grid item xs={ isTabletLanding ? 0 : 12 }>
                <Grid container
                display="flex"
                justifyContent="space-evenly"
                spacing={"8vw"}
                >
                    <Grid item>
                        <Typography
                        style={{fontSize:"1.5vh", ...linkStyle}}
                        onClick={() => navigate(faqPage)}>
                            FAQs
                        </Typography>
                        <Typography
                        style={{fontSize:"1.5vh", ...linkStyle}}
                        onClick={() => navigate(contactUsPage)}>
                            Contact Us
                        </Typography>
                        <Typography
                        style={{fontSize:"1.5vh"}}
                        >
                            About Us
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                        style={{fontSize:"1.5vh", ...linkStyle}}
                        onClick={() => navigate(privacyPolicy)}>
                            Privacy Policy
                        </Typography>
                        <Typography
                        style={{fontSize:"1.5vh", ...linkStyle}}
                        onClick={() => navigate(termsAndConditionsPage)}>
                            Terms
                        </Typography>
                        <Typography
                        style={{fontSize:"1.5vh"}}
                        >
                            Report Misuse
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                        style={{fontSize:"1.5vh"}}
                        >
                            Creditations
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container
                spacing={"2vw"}
                display="flex"
                alignItems="center"
                sx={{
                    mr: isTabletLanding ? "" : "3.5vw",
                    mt: isTabletLanding ? "" : "4vh",
                }}
                >
                    <Grid item>
                        <Typography
                        style={{fontSize:"1.5vh"}}
                        align="center"
                        >
                            Dedicated to
                        </Typography>
                        <Typography
                        style={{fontSize:"1.5vh"}}
                        align="center"
                        >
                            Founder Acarya
                        </Typography>
                        <Typography
                        style={{fontSize:"1.5vh"}}
                        align="center"
                        >
                            HDG A.C. Bhaktivedanta
                        </Typography>
                        <Typography
                        style={{fontSize:"1.5vh"}}
                        align="center"
                        >
                            Swami Srila Prabhupada
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar src={desktopFooter}
                        sx={{height:"11vh", width: "11vh"}}
                        />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default Footer