import { useState, useCallback, useEffect } from "react"
import DropBox from "../IndividualComponents/DropBox"
import ShowImage from "../IndividualComponents/ShowImage"
import imageCompression from "browser-image-compression"
import axios from "../../api/axios"
import { GET_PICS_URL, UPLOAD_PICS_URL } from "../../pages/URLs"
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo"

const DragAndDropComp = ({ images, setImages }) => {

    const accessTokenEmail = useAccessTokenInfo("email")

    const [indexId, setIndexId] = useState([0,1,2,3,4]);
    const [loading, setLoading] = useState([]);

    useEffect(() => {
        setImages([])
        for (let i = 0; i < 5; i++) {
            axios.post(GET_PICS_URL, 
                JSON.stringify({
                    email: accessTokenEmail,
                    picKey: `${i}`
                }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_GET_PICS_KEY}` },
                    withCredentials: true
                }
                ).then(res => {
                    if (res.data === "None" || res.data === "No Picture Found") {
                        return
                    }
                    setImages((prevState) => [
                        ...prevState,
                        { id: i, src: res.data.src },
                    ])
                    const removeIndex = indexId.indexOf(i);
                    if (removeIndex > -1) {
                        indexId.splice(removeIndex, 1);
                    }
                    setIndexId([...indexId])
                })
        }
    }, [])

    const [errMsg, setErrMsg] = useState("");

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length + images.length > 5) {
            setErrMsg("You can only upload a maximum of 5 pictures")
            return
        }
        acceptedFiles.forEach((file) => {
            if (file.size > 50000000) {
                return
            }
            const imageFile = file
            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 700,
                useWebWorker: true
            }
            imageCompression(imageFile, options).then(compressedFile => {
                var reader = new FileReader()
                reader.readAsDataURL(compressedFile)
                reader.onloadend = () => {
                    const copyOfIndex = JSON.parse(JSON.stringify(indexId))
                    const idVal = Math.min(...copyOfIndex)

                    // ? If we need to cut down calls to the api we can upload all images in one go

                    try {
                        if (!loading.includes(idVal)) {
                            loading.push(idVal)
                            setLoading([...loading])
                        }
                        axios.post(UPLOAD_PICS_URL, 
                            JSON.stringify({
                                email: accessTokenEmail,
                                pic: { src: reader.result, id: idVal }
                            }),
                            {
                                headers: { 'Content-Type': 'application/json',
                                'x-functions-key': `${process.env.REACT_APP_UPLOAD_PICS_KEY}` },
                                withCredentials: true
                            }
                            ).then(() => {
                                if (loading.includes(idVal)) {
                                    const removeIndex = loading.indexOf(idVal);
                                    if (removeIndex > -1) {
                                        loading.splice(removeIndex, 1);
                                    }
                                    setLoading([...loading])
                                }
                            })
                    } catch (err) {
                        console.log(err)
                        if (loading.includes(idVal)) {
                            const removeIndex = loading.indexOf(idVal);
                            if (removeIndex > -1) {
                                loading.splice(removeIndex, 1);
                            }
                            setLoading([...loading])
                        }
                        return
                        // TODO continue
                    }
                    setImages((prevState) => [
                        ...prevState,
                        { id: idVal, src: reader.result },
                    ])
                    const removeIndex = indexId.indexOf(idVal);
                    if (removeIndex > -1) {
                        indexId.splice(removeIndex, 1);
                    }
                    setIndexId([...indexId])
                }
            })
        })
    })

    return (
        <div
        style={{padding: "1.5vh"}}
        >
            <DropBox onDrop={onDrop} errMsg={errMsg}/>
            <ShowImage images={images} setImages={setImages} setErrMsg={setErrMsg}
            indexId={indexId}
            setIndexId={setIndexId}
            loading={loading}
            />
        </div>
    )
}

export default DragAndDropComp