import { createContext, useContext, useState } from "react";
import useAccessTokenInfo from "../../../hooks/useAccessTokenInfo";
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import FaceIcon from '@mui/icons-material/Face';
import SavingsIcon from '@mui/icons-material/Savings';
import InterestsIcon from '@mui/icons-material/Interests';
import SettingsIcon from '@mui/icons-material/Settings';
import LinkIcon from '@mui/icons-material/Link';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import PreferencesIconCustom from "../../IndividualComponents/PreferencesIconCustom";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/context/AuthProvider";
import ResponsiveContext from "../../../ResponsiveDesign/ResponsiveProvider";
import { LOGOUT_URL, completeProfilePage, galleryPage, homePage, landingPage, matchingPage, settingsPage, subscriptionsPage, updateProfilePage, usefulLinksPage } from "../../../pages/URLs";
import axios from "../../../api/axios";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import homeText from "../../../text/home.json"

const NavBarContext = createContext({});

const iconStyling = (disabled = false) => {return { fontSize: "26px", fill: disabled ? "#5e5e5e" : "black" }}
const navBarDuration = 1000
const heightOfAvatar = 62
const paddingTop = 20
const heightOfBigAvatar = 100
const paddingLeft = 14

export const NavBarProvider = ({ children, fadeInDuration }) => {
    
    const accessTokenInfo = useAccessTokenInfo()
    const email = accessTokenInfo.email
    const isReadyForMatches = accessTokenInfo?.readyForMatches || false
    const isSubbed = accessTokenInfo?.isSubbed || false
    const isEmailVerified = accessTokenInfo?.isEmailVerified || false

    const { setAuth } = useContext(AuthContext)

    const location = useLocation().pathname
    const navigate = useNavigate()

    const { isLaptop } = useContext(ResponsiveContext)

    const [navButtonDisabled, setNavButtonDisabled] = useState(false);
    const [navToggleDisabled, setNavToggleDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [switchMenu, setSwitchMenu] = useState(false);

    const handleNavBarButton = (page) => {
        if (location === page) return
        setNavButtonDisabled(true)
        setTimeout(() => { setNavButtonDisabled(false) }, fadeInDuration * 2.1)
        if (!isLaptop) {
            setSwitchMenu(false)
            setOpen(false)
        }
        navigate(page)
    }

    const toggleNavBar = () => {
        setOpen(!open)
        setNavToggleDisabled(true)
        setTimeout(() => { setSwitchMenu(!switchMenu) }, navBarDuration/2)
        setTimeout(() => { setNavToggleDisabled(false) }, navBarDuration)
    }

    const navBarPages = [
        {
            label: homeText.authNavBar[0],
            icon: <HomeIcon style={iconStyling()}/>,
            func: () => handleNavBarButton(homePage)
        },
        {
            label: homeText.authNavBar[1],
            icon: <FavoriteIcon style={iconStyling(process.env.REACT_APP_DISABLE_MATCHING === "true" ? (!isReadyForMatches || !isEmailVerified) : (!isReadyForMatches || !isSubbed || !isEmailVerified))}/>,
            func: () => handleNavBarButton(matchingPage),
            disabled: process.env.REACT_APP_DISABLE_MATCHING === "true" ? (!isReadyForMatches || !isEmailVerified) : (!isReadyForMatches || !isSubbed || !isEmailVerified)
        },
        {
            label: homeText.authNavBar[2],
            icon: <FaceIcon style={iconStyling()}/>,
            func: () => handleNavBarButton(isReadyForMatches ? updateProfilePage : completeProfilePage)
        },
        {
            label: homeText.authNavBar[3],
            icon: <PhotoCameraIcon style={iconStyling(!isReadyForMatches)}/>,
            func: () => handleNavBarButton(galleryPage),
            disabled: !isReadyForMatches
        },
        {
            label: homeText.authNavBar[4],
            icon: <SavingsIcon style={iconStyling(process.env.REACT_APP_SUBSCRIPTION_ENABLED !== "true")}/>,
            func: () => handleNavBarButton(subscriptionsPage),
            disabled: process.env.REACT_APP_SUBSCRIPTION_ENABLED !== "true"
        },
        {
            label: homeText.authNavBar[5],
            icon: <SearchIcon style={iconStyling(true)}/>,
            func: () => handleNavBarButton(homePage),
            disabled: true
        },
        {
            label: homeText.authNavBar[6],
            icon: <PreferencesIconCustom size="26px" disabled={true}/>,
            func: () => handleNavBarButton(homePage),
            disabled: true
        },
        {
            label: homeText.authNavBar[7],
            icon: <InterestsIcon style={iconStyling(true)}/>,
            func: () => handleNavBarButton(homePage),
            disabled: true
        },
        {
            label: homeText.authNavBar[8],
            icon: <SettingsIcon style={iconStyling()}/>,
            func: () => handleNavBarButton(settingsPage)
        },
        {
            label: homeText.authNavBar[9],
            icon: <LinkIcon style={iconStyling()}/>,
            func: () => handleNavBarButton(usefulLinksPage)
        },
        {
            label: homeText.authNavBar[10],
            icon: <LogoutIcon style={iconStyling()}/>,
            func: async () => {
                await axios.post(LOGOUT_URL,
                    JSON.stringify({ email: email }),
                    {
                        headers: { 'Content-Type': 'application/json',
                        'x-functions-key': `${process.env.REACT_APP_LOGOUT_KEY}` },
                        withCredentials: true
                    }
                ).then(() => {
                    setAuth("")
                    handleNavBarButton(landingPage)
                })
            }
        },
    ]

    return (
        <NavBarContext.Provider value={{
            navBarPages,
            toggleNavBar,
            handleNavBarButton,
            navButtonDisabled,
            setNavButtonDisabled,
            navToggleDisabled,
            setNavToggleDisabled,
            open,
            setOpen,
            switchMenu,
            setSwitchMenu,
            navBarDuration,
            heightOfAvatar,
            paddingLeft,
            paddingTop,
            heightOfBigAvatar
        }}>
            {children}
        </NavBarContext.Provider>
    )
}

export default NavBarContext;