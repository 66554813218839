import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { VERIFY_USER_URL } from "./URLs"
import useAccessTokenInfo from "../hooks/useAccessTokenInfo"
import MainBody from "../Layout/MainBody"
import LoadingComp from "../components/IndividualComponents/LoadingComp"
import axios from "../api/axios"
import responseCodes from "../api/allowResponseCodes"
import { Grid, Typography } from "@mui/material"
import NonAuthLayout from "../Layout/NonAuthLayout"
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const VerifyUser = () => {
    const { verificationToken, refResponse } = useParams()
    const [success, setSuccess] = useState(null)

    useEffect(() => {

        const verifyUser = async () => {
            if (!verificationToken || !refResponse) {
                setSuccess(false)
                return
            }
            try {
                await axios.post(VERIFY_USER_URL, 
                    JSON.stringify({ refResponse, verificationToken }),
                    {
                        headers: { 'Content-Type': 'application/json',
                        'x-functions-key': `${process.env.REACT_APP_VERIFY_USER_KEY}` },
                        withCredentials: true
                    }
                ).then(async (res) => {
                    if (responseCodes.includes(res?.status)) {
                        setSuccess(true)
                    } else {
                        setSuccess(false)
                    }
                })
            } catch (err) {
                console.log(err?.response?.data)
                setSuccess(false)
            }
        }
        verifyUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <NonAuthLayout>
        <SEO
            title={seoText.default.title}
            description={seoText.default.description}
        />
        {
            success === null ? (
                <Grid container
                style={{
                    height: "100%",
                    width: "100%",
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                    <LoadingComp width="15vw" />
                </Grid>
            ) : success === false ? (
                <Grid container
                style={{
                    height: "100%",
                    width: "100%",
                }}
                sx={{ px: "2vw" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h2" align="center">
                            Response failed
                        </Typography>
                        <Typography variant="h4" align="center">
                            Please contact us at admin@krishnamarriage.com for more information
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container
                style={{
                    height: "100%",
                    width: "100%",
                }}
                sx={{ px: "2vw" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h2" align="center">
                            Response recorded
                        </Typography>
                        <Typography variant="h4" align="center">
                            Thank you for submitting your response
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
        </NonAuthLayout>
    )
}

export default VerifyUser