import { Grid } from "@mui/material"
import { useContext } from "react"
import LayoutContext from "./LayoutProvider"

const TopRightContent = ({ children }) => {

    const { mainBodyWidth,
        navPicWidth,
        rightContentWidth,
        rightContentPaddingTop,
        rightContentInfoHeight,
        rightContentPaddingX } = useContext(LayoutContext)

    return (
        <Grid item
            style={{
                width: rightContentWidth,
                height: `${rightContentInfoHeight}vh`,
                position: "fixed",
                zIndex: 2,
                left: `calc(${navPicWidth} + ${mainBodyWidth})`,
                top: `${rightContentPaddingTop}vh`
            }}
            sx={{
                px: rightContentPaddingX
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            >
                {children}
            </Grid>
    )
}

export default TopRightContent