
import { Checkbox } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import StructureContext from "../../../StructureDecoder/context/StructureProvider";
import CheckedPill from "./CheckedPill";
import Pill from "./Pill";

const CPPillComp = (props) => {

    const { page, setPage } = useContext(StructureContext)
    const path = props.path
    const thisPage = page(path[0])
    const discreteVals = thisPage[path[1]][path[2]].discreteVals
    const values = thisPage[path[1]][path[2]].value

    return (
        <>
        {discreteVals.map((discreteVal) => {
            return (
                <Checkbox
                disableRipple
                checked={values.includes(discreteVal)}
                key={discreteVal}
                value={discreteVal}
                icon={<Pill text={discreteVal}/>}
                checkedIcon={<CheckedPill text={discreteVal}/>}
                onChange={(e) => {
                    if (e.target.checked) {
                        values.push(e.target.value)
                    } else {
                        var index = values.indexOf(e.target.value)
                        if (index !== -1) {
                            values.splice(index, 1)
                        }
                    }

                    thisPage[path[1]][path[2]].value = values
                    setPage(path[0], thisPage)
                }}
                
                size="small"
                sx={{margin:0}}
                />

            )
        })}

        </>
    );
}


export default CPPillComp

