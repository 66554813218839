import { createContext, useEffect, useReducer, useState } from "react";
import { page_1, page_2, page_3, page_4, page_5 } from "../../components/ProfileDecoder/structure";

const StructureContext = createContext({});

export const StructureProvider = ({ children }) => {

    const [progress, setProgress] = useState(0);

    const [page1, setPage1] = useState(structuredClone(page_1));
    const [page2, setPage2] = useState(structuredClone(page_2));
    const [page3, setPage3] = useState(structuredClone(page_3));
    const [page4, setPage4] = useState(structuredClone(page_4));
    const [page5, setPage5] = useState(structuredClone(page_5));

    const [required, setRequired] = useState([]);

    const indexToPage = {
        "1": {
            val: page1,
            set: setPage1
        },
        "2": {
            val: page2,
            set: setPage2
        },
        "3": {
            val: page3,
            set: setPage3
        },
        "4": {
            val: page4,
            set: setPage4
        },
        "5": {
            val: page5,
            set: setPage5
        },
    }
    
    const page = (index) => {
        return indexToPage[index].val
    }
    const setPage = (index, newPage) => {
        indexToPage[index].set({...newPage})
    }

    return (
        <StructureContext.Provider value={{ page, setPage, progress, setProgress, required, setRequired }}>
            {children}
        </StructureContext.Provider>
    )
}

export default StructureContext;