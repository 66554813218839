import { purple } from "@mui/material/colors"

const mainColors = {
    "background": "#FFFDBF",
    "primary": "#FFD76F",
    "primaryText": "#000",
    "accent": "#8D63FF",
    "accentText": "#fff",
    "important": "#E81C09",
    "importantText": "#fff",
    "textFieldBackground": "#fff",
    "textColor": "#000",
    "lightAccent": "#CCD3FF",
    "purpleAccent": purple[500],
    "pink": "#f59de5",
    "green": "#2a9c16",
    "grey": "#71797E",
    "blue": "#7183FF",
    "darkpink": "#EC6DDC"
}

const colorPalette = {
    background: {
        default: mainColors.background
    },
    default: {
        main: mainColors.background,
        contrastText: mainColors.background
    },
    primary: {
        main: mainColors.primary,
        contrastText: mainColors.primaryText
    },
    accent: {
        main: mainColors.accent,
        contrastText: mainColors.accentText
    },
    important: {
        main: mainColors.important,
        contrastText: mainColors.importantText
    },
    textColor: {
        main: mainColors.textColor
    },
    errorText: {
        contrastText: mainColors.important
    },
    lightAccent: {
        main: mainColors.lightAccent
    },
    blackButton: {
        main: mainColors.textColor,
        contrastText: mainColors.accentText
    },
    purpleAccent: {
        main: mainColors.purpleAccent
    }
}

export { colorPalette, mainColors }