import { useNavigate } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import { landingPage } from "./URLs";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"

const Admin = () => {
    const navigate = useNavigate();
    const logout = useLogout()

    const signOut = async () => {
        await logout();
        navigate(landingPage);
    }
    
    return (
        
        <section>
            <SEO
                title={seoText.admin.title}
                description={seoText.admin.description}
            />
            <h1>Admins Page</h1>
            <br/>
            <p>This is the admin page, nothing here for now</p>

            <div className="flexGrow">
                <button onClick={signOut}>Sign Out</button>
            </div>

        </section>
    )
}

export default Admin
