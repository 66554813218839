import jwtDecode from "jwt-decode"
import useAuth from "./useAuth"

const useAccessTokenInfo = (info) => {
    const { auth } = useAuth()
    var accessTokenInfo = ""

    if (Object.keys(auth).length === 0) {
        return {}
    }

    if (!info) {
        accessTokenInfo = jwtDecode(auth.accessToken).UserInfo
        accessTokenInfo["email"] = jwtDecode(auth.accessToken).UserInfo.id
        accessTokenInfo["user"] = jwtDecode(auth.accessToken).UserInfo.id
        if (process.env.REACT_APP_SUBSCRIPTION_ENABLED === "false") {
            accessTokenInfo["isSubbed"] = true;
        }
    } else if (info === "email" || info === "user" || info === "id") {
        accessTokenInfo = jwtDecode(auth.accessToken).UserInfo.id
    } else if (info === "roles") {
        accessTokenInfo = jwtDecode(auth.accessToken).UserInfo.roles
    } else if (info === "dispName") {
        accessTokenInfo = jwtDecode(auth.accessToken).UserInfo.dispName
    }

    return accessTokenInfo
}

export default useAccessTokenInfo