import NonAuthLayout from "../Layout/NonAuthLayout";
import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import commGuidelines from "../text/guidelines.json"

for(let i=0; i<commGuidelines.guidelines.length; i++ ) {
    if(commGuidelines.guidelines[i].text instanceof Array) {
        let joinedText = commGuidelines.guidelines[i].text.join('\n\n')
        commGuidelines.guidelines[i].text = joinedText
    }
}

const Guidelines = () => {
    return (
        <NonAuthLayout>
            <SEO
                title={seoText.guidelines.title}
                description={seoText.guidelines.description}
            />
            <TitleTextPageLayout titleTextArray={commGuidelines.guidelines} headerTextObj={commGuidelines.intro} footerTextObj={commGuidelines.footer}/>
        </NonAuthLayout>
    )
}

export default Guidelines
