import { Fragment } from 'react';
import KMButton from '../IndividualComponents/KMButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useContext, useEffect } from "react";
import ResponsiveContext from '../../ResponsiveDesign/ResponsiveProvider';
import { mainColors } from "../Theme/Colors/palette";
import { Typography } from '@mui/material';

const DialogueBox = (props) => {
  const { isRemoveRightContent, isTablet, isLaptop } = useContext(ResponsiveContext);
  const fullScreen = !isLaptop;

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={true}
        onClose={props.onClick}
        aria-labelledby="dialogue-box"
        style={{
          textAlign: "center",
          position: "absolute",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
        PaperProps={{
          style: {
            backgroundColor: mainColors.background,
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: mainColors.primary,
            padding: "15px"
          }
        }}
      >
        <DialogTitle id="dialogue-box">
          <Typography variant='h3'>{props.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='h6' style={{ color: "black" }}>{props.body}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <KMButton endArrow
            variant={props.buttonVariant}
            text={props.buttonText}
            onClick={props.onClick}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default DialogueBox;