import NonAuthLayout from "../Layout/NonAuthLayout";
import TitleTextPageLayout from "../components/CombinedComponents/TitleTextPageLayout";
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import dataprotection from "../text/dataprotection.json"

for(let i=0; i<dataprotection.policy.length; i++ ) {
    if(dataprotection.policy[i].text instanceof Array) {
        let joinedText = dataprotection.policy[i].text.join('\n\n')
        dataprotection.policy[i].text = joinedText
    }
}

const DataProtection = () => {
    return (
        <NonAuthLayout>
            <SEO
                title={seoText.dataprotection.title}
                description={seoText.dataprotection.description}
            />
            <TitleTextPageLayout titleTextArray={dataprotection.policy} headerTextObj={dataprotection.intro} footerTextObj={dataprotection.footer}/>
        </NonAuthLayout>
    )
}

export default DataProtection
