import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { registerPage } from "../../pages/URLs"
import { motion } from "framer-motion"
import KMButton from "../IndividualComponents/KMButton"
import homeText from "../../text/home.json"


const OurServices = () => {

    const navigate = useNavigate()

    return (
        <Grid container>
            
            <Grid item xs={12}
            display="flex"
            justifyContent="left"
            sx={{mb:"5vh"}}
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                >
                    <Typography variant="h1">
                        {homeText.service.title}
                    </Typography>
                </motion.div>
            </Grid>

            <Grid item xs={12}
            display="flex"
            justifyContent="center"
            sx={{mb:"3vh"}}
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                viewport={{ once: true }}
                >
                    <Typography variant="h4" textAlign="justify">
                    {homeText.service.text1}
                    </Typography>
                </motion.div>
            </Grid>

            <Grid item xs={12}
            display="flex"
            justifyContent="center"
            sx={{mb:"5vh"}}
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                viewport={{ once: true }}
                >
                    <Typography variant="h4" textAlign="justify">
                    {homeText.service.text2}
                    </Typography>
                </motion.div>
            </Grid>

            <Grid item xs={12}
            display="flex"
            justifyContent="right"
            >
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                viewport={{ once: true }}
                >
                    <KMButton endArrow
                    text={homeText.service.button}
                    variant={"accentTitle"}
                    onClick={()=>navigate(registerPage)}/>
                </motion.div>
            </Grid>

        </Grid>
    )
}

export default OurServices

