import { createContext, useContext, useEffect, useReducer, useState } from "react";
import axios from "../../api/axios";
import { page_1, page_2, page_3, page_4, page_5 } from "../../components/ProfileDecoder/structure";
import useAccessTokenInfo from "../../hooks/useAccessTokenInfo";
import { ABOUT_ME_INFO_URL, PARTNER_PREFS_INFO_URL, PERSONAL_INFO_URL, SPIRITUAL_INFO_URL, CREATE_REFERENCES_URL } from "../../pages/URLs";
import StructureContext from "./StructureProvider";

const StructureContextUpdate = createContext({});

export const StructureProviderUpdate = ({ children }) => {

    const { page, setPage } = useContext(StructureContext)
    const accessTokenEmail = useAccessTokenInfo("email")

    const [edit1, setEdit1] = useState(false)
    const [edit2, setEdit2] = useState(false)
    const [edit3, setEdit3] = useState(false)
    const [edit4, setEdit4] = useState(false)
    const [edit5, setEdit5] = useState(false)

    const [copyPage1, setCopyPage1] = useState(structuredClone(page_1));
    const [copyPage2, setCopyPage2] = useState(structuredClone(page_2));
    const [copyPage3, setCopyPage3] = useState(structuredClone(page_3));
    const [copyPage4, setCopyPage4] = useState(structuredClone(page_4));
    const [copyPage5, setCopyPage5] = useState(structuredClone(page_5));

    const [loading, setLoading] = useState(false);

    const copyIndexToPage = {
        "1": {
            val: copyPage1,
            set: setCopyPage1
        },
        "2": {
            val: copyPage2,
            set: setCopyPage2
        },
        "3": {
            val: copyPage3,
            set: setCopyPage3
        },
        "4": {
            val: copyPage4,
            set: setCopyPage4
        },
        "5": {
            val: copyPage5,
            set: setCopyPage5
        },
    }

    const indexToEdit = {
        "1": {
            val: edit1,
            set: setEdit1
        },
        "2": {
            val: edit2,
            set: setEdit2
        },
        "3": {
            val: edit3,
            set: setEdit3
        },
        "4": {
            val: edit4,
            set: setEdit4
        },
        "5": {
            val: edit5,
            set: setEdit5
        },
    }

    const apiURLS = [PERSONAL_INFO_URL, ABOUT_ME_INFO_URL, SPIRITUAL_INFO_URL, PARTNER_PREFS_INFO_URL, CREATE_REFERENCES_URL]
    const apiKeys = [process.env.REACT_APP_CREATE_PERSONAL_INFO_KEY, process.env.REACT_APP_CREATE_ABOUT_ME_INFO_KEY, process.env.REACT_APP_CREATE_SPIRITUAL_INFO_KEY, process.env.REACT_APP_CREATE_PARTNER_PREF_INFO_KEY, process.env.REACT_APP_CREATE_REFERENCES_KEY]

    const getVals = (thisPage) => {
        var valuesObject = {}
        const sections = Object.keys(thisPage)
        sections.forEach((section) => {
            valuesObject[section] = {}
            const fields = Object.keys(thisPage[section])
            fields.forEach((field) => {
                if (field === "completionTime" || field === "empty") {
                    return
                } else {
                    valuesObject[section][field] = thisPage[section][field].value
                }
            })
        })
        return valuesObject
    }

    const dontSave = (index) => {
        setPage(index, copyIndexToPage[index].val)
    }

    const save = (index) => {
        setLoading(true)
        const post = getVals(page(index))
        var finalPost = {email: accessTokenEmail,...post}
        axios.post(apiURLS[index-1], 
            JSON.stringify(finalPost),
            {
                headers: { 'Content-Type': 'application/json',
                'x-functions-key': `${apiKeys[index-1]}` },
                withCredentials: true
            }
            ).then(() => {
                setTimeout(() => {
                    setCopyPage(index, structuredClone(page(index)))
                    setEdit(index, false)
                    setLoading(false)
                }, 1500)
            })
    }

    const setCopyPage = (index, newPage) => {
        copyIndexToPage[index].set({...newPage})
    }

    const edit = (index) => {
        return indexToEdit[index].val
    }

    const setEdit = (index, value) => {
        indexToEdit[index].set(value)
    }

    return (
        <StructureContextUpdate.Provider value={{ dontSave, edit, setEdit, setCopyPage, save, loading }}>
            {children}
        </StructureContextUpdate.Provider>
    )
}

export default StructureContextUpdate;