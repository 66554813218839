import { useState, useEffect, useContext } from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import useToggle from '../hooks/useToggle';
import jwt_decode from 'jwt-decode'
import axios from '../api/axios';
import { default as ROLES } from '../auth/Roles';
import { adminPage, homePage, landingPage, matchmakerPage, registerPage, LOGIN_URL, completeProfilePage, forgotPasswordPage } from './URLs';
import { Checkbox, Grid, TextField, Typography } from '@mui/material';
import NonAuthNavBar from '../components/NavBar/NonAuthNavBar';
import DialogueBox from '../components/IndividualComponents/DialogueBox';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { mainColors } from '../components/Theme/Colors/palette';
import desktopLogin from '../img/desktopLogin-min.jpg'
import Footer from '../components/CombinedComponents/Footer';
import ResponsiveContext from '../ResponsiveDesign/ResponsiveProvider';
import KMButton from '../components/IndividualComponents/KMButton';
import SEO from "../components/IndividualComponents/SEO";
import seoText from "../text/seo.json"
import imageAltText from "../text/imageAlt.json"
import bannerText from "../text/subBanner.json"

const Login = () => {

    const { isTabletLanding } = useContext(ResponsiveContext)

    const { setAuth } = useAuth();

    const navigate = useNavigate();

    const links = [
        {
            name: "Home",
            handleClick: () => navigate(landingPage)
        }
    ]

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    // const [responseQuick, setResponseQuick] = useState(null);
    // const [response, setResponse] = useState(null);
    const [displayPopUp, setDisplayPopUp] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);

    const [loading, setLoading] = useState(false);

    
    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    
    // the useEffect to trigger on first render and check if in the localStorage we already have data about user seen and closed the pop-up
    useEffect(() => {
        let returningUser = localStorage.getItem("seenPopUp");
        setDisplayPopUp(!returningUser);
    }, []);

    const closePopUp = () => {
        localStorage.setItem("seenPopUp", true);
        setDisplayPopUp(false);
    };

    const handleSubmit = async (e) => {
        if (!user || !pwd) {
            setErrMsg("Email and password are required")
            return
        }

        setErrMsg("")
        setLoading(true)

        try {
            const response = await axios.post(LOGIN_URL, 
                JSON.stringify({ email: user, pwd: pwd }),
                {
                    headers: { 'Content-Type': 'application/json',
                    'x-functions-key': `${process.env.REACT_APP_LOGIN_KEY}` },
                    withCredentials: true
                }
            )
            const accessToken = response?.data;
            const roles = jwt_decode(accessToken)?.UserInfo?.roles || []
            const isFirstTime = jwt_decode(accessToken)?.UserInfo?.isFirstTime || false
            setAuth({ user, accessToken });
            setTimeout(() => {
                setUser('');
                setPwd('');
                roles.includes(ROLES.Admin) 
                    ? navigate(adminPage) 
                    : roles.includes(ROLES.Matchmaker) 
                        ? navigate(matchmakerPage)
                        : roles.includes(ROLES.User)
                            ? isFirstTime
                                ? navigate(completeProfilePage)
                                : navigate(homePage)
                            : navigate(landingPage)
            }, 1500)

        } catch (err) {
            if (!err?.response) {
                setTimeout(() => {
                    setErrMsg('No Server Response');
                    setLoading(false)
                }, 1500)
            } else if (err.response?.status === 400) {
                setTimeout(() => {
                    setErrMsg(err.response.data);
                    setLoading(false)
                }, 1500)
            } else if (err.response?.status === 401) {
                setTimeout(() => {
                    setErrMsg('Incorrect email or password');
                    setLoading(false)
                }, 1500)
            } else if (err.response?.status === 500) {
                setTimeout(() => {
                    setErrMsg('Unexpected error, please try again later');
                    setLoading(false)
                }, 1500)
            } else {
                setTimeout(() => {
                    setErrMsg('Login Failed');
                    setLoading(false)
                }, 1500)
            }
        }

        }


    return (

        <Grid container height="100vh"
        direction="column"
        >
            <SEO
                title={seoText.login.title}
                description={seoText.login.description}
            />

            <Grid item height="auto">
                <NonAuthNavBar links={links}/>
            </Grid>

            <Grid item
            width="100vw"
            xs={true}
            >
            {process.env.REACT_APP_SUBSCRIPTION_ENABLED !== "true" && displayPopUp && (
                <DialogueBox 
                    onClick={closePopUp}
                    title={bannerText.title}
                    body={bannerText.banner}
                    buttonVariant="impTitle"
                    buttonText="Understood"
                />
            )}

            <Grid container
            direction="row"
            height="100%"
            >

                <Grid item xs={ isTabletLanding ? true : 12 } sx={{mx:"6vw"}}
                display="flex"
                alignItems="center"
                height="auto"
                >

                    <Grid container spacing={"2vh"}>

                        <Grid item xs={12}>
                            <Typography variant="h2">
                                Login
                            </Typography>
                        </Grid>

                        <Grid item xs={4}
                        display="flex"
                        alignItems="center"
                        >
                            <Typography variant="h4">
                                Email
                            </Typography>
                        </Grid>
                        <Grid item xs={8}
                        display="flex"
                        justifyContent="flex-end"
                        >
                            <TextField size="small"
                            value={user}
                            onChange={(e) => {
                                setUser(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    handleSubmit()
                                }
                            }}
                            fullWidth/>
                        </Grid>

                        <Grid item xs={4}
                        display="flex"
                        alignItems="center"
                        >
                            <Typography variant="h4">
                                Password
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField size="small"
                            value={pwd}
                            type="password"
                            onChange={(e) => {
                                setPwd(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    handleSubmit()
                                }
                            }}
                            fullWidth/>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ textDecoration: "underline", cursor: "pointer" }}
                            align='end' onClick={() => navigate(forgotPasswordPage)}>
                                Forgot your password?
                            </Typography>
                        </Grid>

                        <Grid item xs={12}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <span style={{
                                display: "inline-flex",
                                alignItems: "center"
                            }}>
                                <Checkbox
                                onChange={toggleCheck}
                                checked={check}
                                color='accent'/>
                                <Typography variant='h6'>
                                    Remember Me
                                </Typography>
                            </span>
                            <KMButton endArrow
                            variant={"impTitle"}
                            text={"Sign In"}
                            onClick={handleSubmit}
                            loading={loading}/>
                        </Grid>

                        {/* temporary grid item, please ignore for now */}
                        <Grid item>
                            <Typography variant="h6" style={{ color: "red" }}>
                                {errMsg}
                            </Typography>
                        </Grid>

                    </Grid>

                </Grid>

                { isTabletLanding && <Grid item xs={true}
                height="auto"
                >
                    <Grid container
                    title={imageAltText.login.sideImg}
                    style={{backgroundImage: `url(${desktopLogin})`,
                    backgroundSize: "cover",
                    height: '100%',
                    backgroundPosition: "center"
                    }}
                    >
                        <Grid container
                        style={{
                            width: "5vw",
                            height: "100%"}}
                        sx={{bgcolor: "rgba(255, 215, 111, 0.6)"}}
                        >
                        </Grid>
                    </Grid>
                </Grid>}



            </Grid>
            </Grid>

            <Grid item height="auto"
            sx={{bgcolor: mainColors.primary,
            px:"8vw", py:"1.5vh"
            }}
            >
                <Footer/>
            </Grid>


        </Grid>

    )
}

export default Login
