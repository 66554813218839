import { Button, Typography } from "@mui/material"
import { mainColors } from "../Theme/Colors/palette"

const UncheckedPills = (props) => {

    const pillText = props.text
    const updatePill = props.func

    return (
        <Button
        variant="outlined"
        disableElevation
        disableRipple
        sx={{"&:hover": { backgroundColor: "transparent", borderWidth: 3, borderColor: mainColors.background },
        borderWidth: 3, borderColor: mainColors.background,
        mx:"1vw", my:"1vh", minWidth:"13vw"
        }}
        onClick={() => {
            updatePill(pillText)
        }}
        >
            <Typography variant="h6"
            fontWeight="bold"
            color={mainColors.background}
            fontFamily="Quicksand"
            >
                {pillText.toUpperCase()}
            </Typography>
        </Button>
    )
}

export default UncheckedPills